import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MessageService {
    private messageSource = new BehaviorSubject<any>([]);
    currentMessage = this.messageSource.asObservable();
    setMessageInfo(arrayData: any) {
        this.messageSource.next(arrayData);
    }
}
