import { Component, Input, OnInit, ElementRef, HostListener, OnChanges } from "@angular/core";
import { trigger, state, animate, transition, style, AnimationEvent } from "@angular/animations";
import { MessageService } from "../services/message.service";


@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
  animations: [
    trigger("visibility", [
      state("none", style({ top: "120px", width: "600px", opacity: 1, display: "none", color: "#0A0A0A" })),
      state("Success", style({ top: "120px", width: "600px", opacity: 1, display: "block", color: "#0A0A0A" })),
      state("Error", style({ top: "120px", width: "600px", opacity: 1, display: "block", color: "#0A0A0A" })),
      state("Info", style({ top: "120px", width: "600px", opacity: 1, display: "block", color: "#0A0A0A" })),
      state("Warn", style({ top: "120px", width: "600px", opacity: 1, display: "block", color: "#0A0A0A" })),
      transition("none => Success", animate("0ms")),
      transition("none => Error", animate("0ms")),
      transition("none => Info", animate("0ms")),
      transition("none => Warn", animate("0ms")),
      transition("Success => none", animate("0ms")),
      transition("Info => none", animate("0ms")),
      transition("Warn => none", animate("0ms"))
    ])
  ]
})


export class MessageComponent implements OnChanges {
  @Input() visible: boolean;
  @Input() message: string;
  @Input() messageType = "none";
  public icon;
  constructor(private myElement: ElementRef, private msgService: MessageService, ) {
  }

  ngOnChanges() {
    this.msgService.currentMessage.subscribe(data => {
        this.visible = data.visible;
      this.message = data.reponseMsg;
      this.messageType = data.type;
      this.icon = this.messageType;
    });

  }

  animationDone(event: AnimationEvent) {

    /*if(this.messageType=="Success" || this.messageType=="Info" || this.messageType=="Warn") {
    this.messageType = "none";
    } */

  }
  clear() {
    this.messageType = "none";
  }

}

