
import { Component, OnInit, HostListener, ViewChild, Input, OnChanges } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { Disclaimer } from './disclaimer';


@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  providers: [MessageService]
})

export class DisclaimerComponent implements OnInit {

  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;

  disclaimer: Disclaimer;
  disclaimerData: Disclaimer[] = [];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  totallength: number;
  disclaimerFinalData: any = [];
  modelKey: String;
  showMessage:String;
  responseMsg:string;
  messageType:String;
  public selectecdvalues: String;
  showSpinner: boolean = false;
  showTable: boolean = false;


  public columnList = ['action', 'disclaimerReferenceCode', 'disclaimerShortDescription',
    'disclaimerTextInEnglish', 'disclaimerTextInFrench',
    'activeIndicator'];
  userListMatTabDataSource = new MatTableDataSource<Disclaimer>(this.disclaimerData);


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;

  constructor(private modelService: ModelService, private messageService: MessageService) {
    this.disclaimer = new Disclaimer();
  }

  ngOnInit() {


    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();

    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);

    this.selectecdvalues = this.vehiclesdetails.division + " " + this.vehiclesdetails.modelYearNumber + " " + this.vehiclesdetails.modelLineCode;



  }

  getAlldata() {
    this.disclaimerData = [];
    this.modelService.getDisclaimersData(this.vehiclesdetails.division, this.vehiclesdetails.modelLineCode, this.vehiclesdetails.modelYearNumber,
    ).subscribe(disclaimerdata => {
      if(disclaimerdata["disclaimerVO"]!=null){
          this.disclaimerData = disclaimerdata["disclaimerVO"]
          ;
        if (this.disclaimerData.length > 0) {
          this.modelKey = this.disclaimerData[0].modelKey;
        }
        this.userListMatTabDataSource.data = this.disclaimerData;
        this.showSpinner = false;
        this.showTable = true;
        setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
      }
      else{
        this.showSpinner = false;
        this.showTable = true;
      }
    });
  
  }

  addRow(index) {

    if (this.userListMatTabDataSource.data != null && this.userListMatTabDataSource.data.length != 0) {
      let disclaimer = new Disclaimer();
      disclaimer.edited = true;
      disclaimer.activeIndicator = "Y";
      disclaimer.editedCheck = "New";
      this.disclaimerData.splice(0, 0, disclaimer);
      this.editedData.push(disclaimer);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.disclaimerData;
    }
    else {
      this.disclaimerData = [];
      let disclaimer = new Disclaimer();
      disclaimer.edited = true;
      disclaimer.disclaimerReferenceCode = "";
      disclaimer.modelKey = "";
      disclaimer.modelYearNumber = this.vehiclesdetails.modelYearNumber;
      disclaimer.modelLineCode = this.vehiclesdetails.modelLineCode;
      disclaimer.disclaimerTextInEnglish = "";
      disclaimer.disclaimerTextInFrench = "";
      disclaimer.disclaimerShortDescription = "";
      disclaimer.languageCode = "";
      disclaimer.activeIndicator = "Y";
      disclaimer.localizedDisclaimerKey = "";
      disclaimer.editedCheck = "New";
      this.editedData.push(disclaimer);
      this.disclaimerData.push(disclaimer);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.disclaimerData;
      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
  }
  applyFilter(filterValue: string) {
          this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
     
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }

  edited(disclaimer, index2) {
    if (disclaimer.editedCheck != null && disclaimer.editedCheck != "New") {
      for (let index = 0; index < this.editedData.length; index++) {
        if (this.editedData[index].editedCheck == disclaimer.editedCheck) {
          this.editedData[index].edited = false;
          this.editedData[index].editedCheck = null;
          this.editedData.splice(index, 1);
          this.getAlldata();
        }
      }
    }
    else if (disclaimer.editedCheck != null && disclaimer.editedCheck == "New") {
      disclaimer.edited = false;
      disclaimer.editedCheck = null;
      this.disclaimerData.splice(index2, 1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.disclaimerData;
      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
    else {
      disclaimer.edited = true;
      disclaimer.editedCheck = index2;
      this.editedData.push(disclaimer);
    }
  }


  save() {

    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);

    } else {
      this.editedData.forEach(rowData => {
        if (rowData.disclaimerTextInEnglish != null && rowData.edited == true) {
          let disclaimer = new Disclaimer();
          disclaimer.modelKey = this.modelKey;
          disclaimer.languageCode = "en";
          if (rowData.editedCheck == "New") {
            disclaimer.disclaimerReferenceCode = this.vehiclesdetails.modelYearNumber + "_" + this.vehiclesdetails.modelLineCode + "_" + rowData.disclaimerReferenceCode;
          }
          else {
            disclaimer.disclaimerReferenceCode = rowData.disclaimerReferenceCode;
          }
          disclaimer.disclaimerShortDescription = rowData.disclaimerShortDescription;
          disclaimer.activeIndicator = rowData.activeIndicator;
          disclaimer.disclaimerText = rowData.disclaimerTextInEnglish;
          disclaimer.localizedDisclaimerKey = "";
          disclaimer.modelYearNumber = this.vehiclesdetails.modelYearNumber;
          disclaimer.modelLineCode = this.vehiclesdetails.modelLineCode;
          this.disclaimerFinalData.push(disclaimer);
        }
        if (rowData.disclaimerTextInFrench != null && rowData.edited == true) {
          let disclaimer = new Disclaimer();
          disclaimer.modelKey = this.modelKey;
          disclaimer.languageCode = "fr";
          if (rowData.editedCheck == "New") {
            disclaimer.disclaimerReferenceCode = this.vehiclesdetails.modelYearNumber + "_" + this.vehiclesdetails.modelLineCode + "_" + rowData.disclaimerReferenceCode;
          }
          else {
            disclaimer.disclaimerReferenceCode = rowData.disclaimerReferenceCode;
          }

          disclaimer.disclaimerShortDescription = rowData.disclaimerShortDescription;
          disclaimer.activeIndicator = rowData.activeIndicator;
          disclaimer.disclaimerText = rowData.disclaimerTextInFrench;
          disclaimer.localizedDisclaimerKey = "";
          disclaimer.modelYearNumber = this.vehiclesdetails.modelYearNumber;
          disclaimer.modelLineCode = this.vehiclesdetails.modelLineCode;
          this.disclaimerFinalData.push(disclaimer);
        }
      }
      );
      if (this.disclaimerFinalData.length > 0) {
        this.showSpinner = true;
        this.showTable = false;
        this.modelService.SaveDisclaimer(this.disclaimerFinalData).subscribe(res => {
          this.responseStatusAdd(res);
          this.editedData = [];
          this.disclaimerFinalData = [];
          this.getAlldata();
        });
      }
      else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }

  }


  responseStatusAdd(res) {

    let resarr = res;
    if (resarr.responseCode == "0000") {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }

}