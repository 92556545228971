import { Component, OnInit ,HostListener,ViewChild,inject,Input, OnChanges, Output,EventEmitter} from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EimJson } from './eimjson';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import {FormControl, Validators} from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService} from '../services/message.service'
import { EIMDescriptionComponent } from './eimdescription/eimdescription.component';
import { EIMDescription } from './eimdescription/eimdescription';




declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-eimjson',
  templateUrl: './eimjson.component.html',
  styleUrls: ['./eimjson.component.scss'],
  providers: [ MessageService]
})

export class EIMJsonComponent implements OnInit  {

  @Input("vehiclesdetails")vehiclesdetails:VehicleDetails;


  eimjson:EimJson;
  eimjsonData: EimJson[]=[];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any=[];
  eimjsonFinalData:any=[];
  optionGroupCodeList:any=[];
  showMessage:String;
  responseMsg:string;
  messageType:String;
  isLoadingResults:boolean=false;
  versionDataForPackage:any=[];
  count:number=0;
  eimDescVo:any=[];
  eimDescVoData:any=[];
  showSpinner:boolean = false;
  showTable:boolean = false;
  @Output() refreshPackage = new EventEmitter<boolean>();
  value:boolean=false;
  public columnList = ['action','eimspecposition','eimspeclength','option'
];
  userListMatTabDataSource = new MatTableDataSource<EimJson>(this.eimjsonData);

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('testForm',{static:false}) testForm: NgForm;
 

  constructor(private modelService:ModelService,private messageService:MessageService, public dialog: MatDialog) { 
 
  }

  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner=true;
    this.showTable=false;
    this.getAlldata();
    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
    this.userListMatTabDataSource.sort = this.sort; 
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
  }
  
  getAlldata() {
    this.eimjsonData=[];
    this.modelService.getEIMData(this.vehiclesdetails.division,this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(eimdata=>{
        if(eimdata["eimVO"]!=null){
          eimdata["eimVO"].forEach(rowData=>{
            this.eimjsonData.push(rowData);
          });
           this.userListMatTabDataSource.data = this.eimjsonData;
           this.showSpinner=false;
           this.showTable=true;
        }
        else{
          this.showSpinner=false;
          this.showTable=true;
        }
    
    });  
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }

  edited(eimjson,index2) {
   
    if(eimjson.editedCheck!=null && eimjson.editedCheck!="New"){
      for(let index = 0;index<this.editedData.length;index++){
        if(this.editedData[index].editedCheck==eimjson.editedCheck){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
          this.getAlldata();
        }
      } 
     }
     else if(eimjson.editedCheck!=null && eimjson.editedCheck=="New"){
      eimjson.edited=false;
      eimjson.editedCheck=null;
      this.eimjsonData.splice(index2,1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.eimjsonData;
      setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
     }
     else{ 
      eimjson.edited = true;
      eimjson.editedCheck=index2;
      for(let index = 0;index<this.editedData.length;index++){
        if(this.editedData[index].eimspecposition==eimjson.eimspecposition){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
        }
      }
      this.editedData.push(eimjson);
     } 
  
   }
   addRow() {
   if(this.userListMatTabDataSource.data !=null && this.userListMatTabDataSource.data.length!=0)
    {
          let eimjson = new EimJson();
          eimjson.edited=true; 
          eimjson.editedCheck="New";
          eimjson.modellineCode=this.vehiclesdetails.modelLineCode;
          eimjson.modelYear=this.vehiclesdetails.modelYearNumber;
          eimjson.eimDescVO=[];
          this.eimjsonData.splice(0, 0, eimjson);
          this.editedData.push(eimjson);
          this.userListMatTabDataSource.data = [];
          this.userListMatTabDataSource.data = this.eimjsonData;
          setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
   }
   else 
   {
         this.eimjsonData=[];
         let eimjson = new EimJson();
         eimjson.edited=true; 
         eimjson.editedCheck="New";
         eimjson.modellineCode=this.vehiclesdetails.modelLineCode;
         eimjson.modelYear=this.vehiclesdetails.modelYearNumber;
         eimjson.eimDescVO=[];
         this.eimjsonData.push(eimjson);
         this.editedData.push(eimjson);
         this.userListMatTabDataSource.data = [];
         this.userListMatTabDataSource.data = this.eimjsonData;
         setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
         setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
   }
 }
  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      console.log(this.editedData);
      this.editedData.forEach(rowData=>{
        if(rowData.edited==true || rowData.edited==undefined){
          this.eimjsonFinalData.push(rowData);
        }
      });
      if(this.eimjsonFinalData.length>0){
          this.showSpinner=true;
          this.showTable=false;
          this.modelService.saveEIMData(this.eimjsonFinalData).subscribe(res=>{
          this.editedData=[];
          this.eimjsonFinalData=[];
          this.responseStatusAdd(res);
          this.getAlldata();
          
          }); 
      }
      else{
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    } 

  }

  responseStatusAdd(res) {
      let resarr = res;
      if (resarr.responseCode == "0000") {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
          this.messageService.setMessageInfo(msgObj);
      }
      else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
      }
  }
  openPopup(eimjson: EimJson) {
    let count =0;
      const dialogRef = this.dialog.open(EIMDescriptionComponent, {
        width: '1000px',
        height:'600px',
        disableClose: true,
        data: {eimjson: eimjson}
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result!=null && this.editedData.length>0){
          for(let x=0 ;x<this.editedData.length;x++){
            if(eimjson.eimspecposition==this.editedData[x].eimspecposition){
              this.editedData.splice(x,1);
              count++;
              let eimjsonData =  new EimJson();
              if(eimjson.modelKey==null)
              eimjsonData.modelKey=null;
              else
              eimjsonData.modelKey=eimjson.modelKey;
              eimjsonData.eimspeckey=eimjson.eimspeckey;           
              eimjsonData.eimspecposition=eimjson.eimspecposition;
              eimjsonData.eimspeclength=eimjson.eimspeclength;
              eimjsonData.modellineCode=eimjson.modellineCode;
              eimjsonData.modelYear=eimjson.modelYear;
              if(eimjson.eimDescVO.length==0){
              result.forEach(record=>{
                  eimjson.eimDescVO.push(record);
             });
            }
            eimjsonData.eimDescVO=eimjson.eimDescVO;
                this.editedData.push(eimjsonData);
            }

          }
          if(count==0){
            this.eimDescVoData=[];
            let eimjsonData =  new EimJson();
              if(eimjson.modelKey==null)
                eimjsonData.modelKey=null;
              else
                eimjsonData.modelKey=eimjson.modelKey;
              eimjsonData.eimspeckey=eimjson.eimspeckey;           
              eimjsonData.eimspecposition=eimjson.eimspecposition;
              eimjsonData.eimspeclength=eimjson.eimspeclength;
              eimjsonData.modellineCode=eimjson.modellineCode;
              eimjsonData.modelYear=eimjson.modelYear;
              if(eimjson.eimDescVO.length==0){
                result.forEach(record=>{
                    eimjson.eimDescVO.push(record);
               });
              }
              eimjsonData.eimDescVO=eimjson.eimDescVO;
              this.editedData.push(eimjsonData);
          }
      }
      else{
        this.eimDescVoData=[];
              let eimjsonData =  new EimJson();
              if(eimjson.modelKey==null)
                eimjsonData.modelKey=null;
              else
                eimjsonData.modelKey=eimjson.modelKey;
              eimjsonData.eimspeckey=eimjson.eimspeckey;           
              eimjsonData.eimspecposition=eimjson.eimspecposition;
              eimjsonData.eimspeclength=eimjson.eimspeclength;
              eimjsonData.modellineCode=eimjson.modellineCode;
              eimjsonData.modelYear=eimjson.modelYear;
             
                result.forEach(record=>{
                  eimjsonData.eimDescVO.push(record);
               });
              
              //eimjsonData.eimDescVO=eimjson.eimDescVO;
              this.editedData.push(eimjsonData);
      }
    
      console.log(this.editedData);
   
      });
  }

  onSortOrderChange(number,index){
    if(number.value!=null && number.value!=""){
            for(let x=0;x<this.userListMatTabDataSource.data.length;x++){
                if(this.userListMatTabDataSource.data[x].eimspecposition!=null && index!=x){
                    if(this.userListMatTabDataSource.data[x].eimspecposition==number.value)
                    {
                      this.testForm.form.get('eimspecposition'+'-'+index).setErrors({'numberExists':'Number already Exists'});
                      break;    
                    }
                }
            } 
    }
  }

}













   
