import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatAccordion } from '@angular/material';
import { VehicleDetails } from './details'
import { ModelService } from '../services/model.service';
import { version } from 'punycode';
import { VersionComponent } from '../version/version.component';
import { AuthenticationService } from '../services/authentication.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {
  title = 'mcm';
  navLinks: any[];
  activeLinkIndex = -1;
  panelOpenState = false;
  vehicledetails: VehicleDetails;
  accordionList: any;
  
  years: any = [];
  modelsList: any = [];
  versionCode: any = [];
  details: VehicleDetails;
  isViewable: boolean = false;
  isViewable2: boolean = false;
  isViewable4: boolean = false;
  isViewable3: boolean = false;
  isViewable5: boolean = false;


  constructor(private router: Router, private modelService: ModelService) {
    this.vehicledetails = new VehicleDetails();
  }
  ngOnInit() {
    
    document.getElementById('modelScreen').style.display = 'none';
    document.getElementById('versionScreen').style.display = 'none';
    document.getElementById('OnVersionSelection').style.display = 'none';
  }
  OnDivisionchange() {
    this.years = [];
    this.vehicledetails.modelYearNumber = "";
    this.vehicledetails.modelLineCode = "";
    this.vehicledetails.versionCode = "";
    document.getElementById('modelScreen').style.display = 'none';
    document.getElementById('versionScreen').style.display = 'none';
    document.getElementById('OnVersionSelection').style.display = 'none';
    this.isViewable = false;
    this.isViewable2 = false;
    this.isViewable3 = false;
    this.isViewable4 = false;
    this.isViewable5 = false;
    this.modelService.getModelYears(this.vehicledetails.division).subscribe(data => {
      this.years = data["modelVO"];
    });
  }
  OnModelYearchange() {
    this.modelsList = [];
    this.versionCode = [];
    this.vehicledetails.modelLineCode = "";
    this.vehicledetails.versionCode = "";
    document.getElementById('modelScreen').style.display = 'none';
    document.getElementById('versionScreen').style.display = 'none';
    document.getElementById('OnVersionSelection').style.display = 'none';
    this.isViewable = false;
    this.isViewable2 = false;
    this.isViewable4 = false;
    this.isViewable3 = false;
    this.isViewable5 = false;
    this.modelService.getModelsByYear(this.vehicledetails.division, this.vehicledetails.modelYearNumber).subscribe(models => {
      this.modelsList = models["modelVO"];
    });
  }
  OnModelchange() {
    this.vehicledetails.versionCode = "";
    this.versionCode = [];
    this.details = this.vehicledetails;
    this.isViewable = false;
    this.isViewable2 = true;
    this.isViewable3 = false;
    this.isViewable4 = false;
    this.isViewable5 = false;
    this.modelService.getVersionCode(this.vehicledetails.modelYearNumber, this.vehicledetails.division,
      this.vehicledetails.modelLineCode).subscribe(versionCodesdata => {
        this.versionCode = versionCodesdata["versionVO"];
        this.isViewable = true;
        this.isViewable3 = true;
        this.isViewable4 = true;
        document.getElementById('modelScreen').style.display = 'block';
        document.getElementById('versionScreen').style.display = 'block';
        document.getElementById('OnVersionSelection').style.display = 'none';
      });
  }


  OnVersionchange() {
    
    this.isViewable5 = false;
    this.isViewable2 = false;
    this.details = this.vehicledetails;
    setTimeout(() => {
      this.isViewable2 = true;
      this.isViewable5 = true;
    }, 1000);
    document.getElementById('OnVersionSelection').style.display = 'block';


  }

  refreshPackage($event) {
    this.isViewable4 = false;
    this.details = this.vehicledetails;
    setTimeout(() => {
      this.isViewable4 = $event;
      this.modelService.getVersionCode(this.vehicledetails.modelYearNumber, this.vehicledetails.division,
        this.vehicledetails.modelLineCode).subscribe(versionCodesdata => {
          this.versionCode = versionCodesdata["versionVO"];
        });
    }, 1000);
  }

  refreshVersion($event) {
    this.isViewable3 = false;
    this.details = this.vehicledetails;
    setTimeout(() => {
      this.isViewable3 = $event;
    }, 1000);
  }

  refreshComplexity($event) {
    this.isViewable5 = false;
    console.log($event);
    this.details = this.vehicledetails;
    setTimeout(() => {
      this.isViewable5 = $event;
    }, 1000);
  }









}
