export class Packages {
    modelLineCode:  String;
    modelYear:  String;
    modelKey: String;
    versionCode:  String;
    versionKey: Number;
    languageCode: String;
    nciModelCode:  String;
    optionGrpCode:  String;
    packageCode: String;
    packageDescriptionEnglish: String;
    packageDescriptionFrench : String;
    msrp:String;
    activeIndicator:  String;
    equipmentKey:String;
    sortOrdernumber:String;
    disclaimerRefCode:String;
    availability: String;
    edited: boolean=undefined;
    
    }