import { Component, OnInit,HostListener,ViewChild,Inject,Input } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog} from '@angular/material/dialog';
import { MessageService} from '../services/message.service'
import { Specification } from "./specification";
import {FormControl, Validators,NgForm} from '@angular/forms';
import { VehicleDetails } from './../details/details';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss'],
  providers: [MessageService]
})
export class SpecificationComponent  {
  @Input("vehiclesdetails")vehiclesdetails:VehicleDetails;


  specificationData: Specification[]=[];
  
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any=[];
  totallength:number;
  versionCodeList:any=[];
  versionFinalData:any=[];
  optionGroupCodeList:any=[];
  showMessage:String;
  responseMsg:string;
  messageType:String;
  disclaimerRefCodeData:any=[];
  specifictionFinalData:any=[];
  buttonDisabled:boolean=true;
  count:number=0;
  availabilityList :any=[];
  showSpinner:boolean = false;
  showTable:boolean = false;
  listofversions: any[] = [];

  subTypeList:any=[];
  typeList:any=[];
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();

  public columnList = [
  'action',
  'type',
  //'subType',
  'eqpmntCode',
  'specificationNameEnglish',
  'specificationNameFrench',
  'valueEnglish',
  'valueFrench',
  'catCode',
  'sortOrdernumber',
  'versionsAvailability',
  'activeIndicator'];

 
 
  userListMatTabDataSource = new MatTableDataSource<Specification>(this.specificationData);

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('testForm',{static:false}) testForm: NgForm;

  constructor(private modelService:ModelService,private messageService:MessageService) 
  { 
  }

  ngOnInit() {
    //console.log(this.vehiclesdetails);
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner=true;
    this.showTable=false;
    this.getAlldata();
    this.getAllTrimsdata();
    this.totallength=this.userListMatTabDataSource.data.length;
    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
     setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
     
  }

  applyFilter(filterValue: string) {
     this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }
  
  getAlldata() {

    this.specificationData=[];
   
    this.modelService.getspecificationData(this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(specificationData=>{ 
        if(specificationData["specificationVO"]!=null){
          specificationData["specificationVO"].forEach(rowData=>{
         
          this.specificationData.push(rowData);
        });
          this.userListMatTabDataSource.data = this.specificationData;
          this.showSpinner = false;
          this.showTable = true;
          setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
        }
        else{
          this.showSpinner = false;
          this.showTable = true;
        }
      });
   
  }
  getAllTrimsdata() {
     
   // this.accessoryData = [];
    let data = [];
    this.modelService.getAccessoriesData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode
    ).subscribe(data => {
      if (data["accessoryVo"].length > 0 && data["accessoryVo"]!=null) {
        data["accessoryVo"].forEach(row => {
          this.listofversions = row.versionsAvailability;
          let versiondata = [];
          row.versionsAvailability.forEach(check => {
            if (check.available == true) {
              versiondata.push(check.versionCode);
            }
          });
          
          row.checkedVersionsCodeList = versiondata;
        //  row.accessorySubType=data["accessorySubTypeList"];
       //   this.accessoryData.push(row);
          setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
          });
        }
      else {
        let tempdata = data["availableVersions"];
        tempdata.forEach(element => {
          let obj = {
            versionCode: element
          }
          this.listofversions.push(obj);
        });
      }
     
     // this.userListMatTabDataSource.data = this.accessoryData;
      this.showSpinner = false;
      this.showTable = true;
      // setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      // setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
    });
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }
  edited(specification,index2) {
    if(specification.editedCheck!=null && specification.editedCheck!="New"){
      for(let index = 0;index<this.editedData.length;index++){
        if(this.editedData[index].editedCheck==specification.editedCheck){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
        //  this.getAlldata();
        }
      } 
     }
     else if(specification.editedCheck!=null && specification.editedCheck=="New"){
      specification.edited=false;
      specification.editedCheck=null;
      this.specificationData.splice(index2,1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.specificationData;
      setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
     }
     else{ 
      this.getDiclaimeRefCodes();
      specification.edited = true;
      specification.editedCheck=index2;
      this.editedData.push(specification);
     } 
   }

  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      
      
        this.editedData.forEach(rowData=>{
          if(rowData.edited==true){
            rowData.edited=false;
            rowData.editedCheck=null;

            
            this.specifictionFinalData.push(rowData);
          }
        
        });
      if(this.specifictionFinalData.length>0){
        
        this.showSpinner=true;
        this.showTable=false;
        this.modelService.saveSpecificationData(this.specifictionFinalData).subscribe(res=>{
          this.responseStatusAdd(res);
          this.getAlldata();
          this.specifictionFinalData=[];
          this.editedData=[];
          });
      }
      else{
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    } 
  }

  getDiclaimeRefCodes(){
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.division).subscribe(disclaimerRefCodeData =>{
          this.disclaimerRefCodeData=disclaimerRefCodeData;
          this.filteredSearch.next(this.disclaimerRefCodeData.slice());
          this.disclaimerRefcodeSearch.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filteredSearchData();
            });
       });
  }
    addRow() {
      this.getDiclaimeRefCodes();
      if(this.userListMatTabDataSource.data !=null && this.userListMatTabDataSource.data.length!=0)
      {
        this.modelService.getSpecificationType().subscribe(
          res=>{
            this.typeList=res;
          }
        );
        let specification = new Specification();
        specification.edited=true; 
        specification.editedCheck="New";
        specification.eqpmntCode=null;
        specification.msrp="0";
        specification.checkedVersionsCodeList = [];
        specification.modelLineCode=this.vehiclesdetails.modelLineCode;
        specification.modelYear=this.vehiclesdetails.modelYearNumber;
        specification.versionCode=this.vehiclesdetails.versionCode;
        specification.activeIndicator="Y",
        this.specificationData.splice(0, 0, specification);
        this.editedData.push(specification);
        this.userListMatTabDataSource.data = [];
        this.userListMatTabDataSource.data = this.specificationData;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
      else 
      {
        this.modelService.getSpecificationType().subscribe(
          res=>{
            this.typeList=res;
          }
        );
            this.specificationData=[];
            let specification = new Specification();
            specification.edited=true; 
            specification.eqpmntCode=null;
            specification.editedCheck="New";
            specification.checkedVersionsCodeList = [];
            specification.modelLineCode=this.vehiclesdetails.modelLineCode;
            specification.modelYear=this.vehiclesdetails.modelYearNumber;
            specification.versionCode=this.vehiclesdetails.versionCode;
            specification.activeIndicator="Y";
            specification.msrp="0";
            this.specificationData.push(specification);
            this.editedData.push(specification);
            this.userListMatTabDataSource.data = [];
            this.userListMatTabDataSource.data = this.specificationData;
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
    }

  
    responseStatusAdd(res) {
      let resarr = res;
      if (resarr.responseCode == "0000" || resarr.responseCode == "0005") {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
          this.messageService.setMessageInfo(msgObj);
      }
      else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
      }
  }
  onTypeChange(specification,index){
  this.subTypeList=[];
  this.modelService.getSpecificationSubType(specification.type).subscribe(
    res=>{
      this.subTypeList=res;
    }); 
  }

  onSortOrderChange(Type,number,index){
    if(number.value!=null && number.value!=""){
            for(let x=0;x<this.userListMatTabDataSource.data.length;x++){
              if (this.userListMatTabDataSource.data[x].type == Type)
              {
                if(this.userListMatTabDataSource.data[x].sortOrdernumber!=null && index!=x){
                    if(this.userListMatTabDataSource.data[x].sortOrdernumber==number.value)
                    {
                       this.testForm.form.get('sortOrdernumber'+'-'+index).setErrors({'numberExists':'Number already Exists'});
                       break;    
                    }
                }
            } 
          }
    }
  }
  
  
  private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;
   
    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
  this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank => 
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}