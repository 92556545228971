import { NumberFormatStyle } from '@angular/common';

export class Version {
    id:number;
    modelKey: String;
    nciModelCode:  String;
    optionGroupCode:  String;
    modelLineCode:  String;
    modelYear:  String;
    versionCode:  String;
    versionNameEnglish:String;
    versionNameFrench:String;
    trimNameEnglish:String;
    trimNameFrench:String;
    versionMSRPAmount: String;
    freightPriceAmount:  String;
    versionKey:  String;
    activeIndicator:  String;
    edited: boolean=undefined;
    isPackage:String
    versionDefined:String;
}