import { Component, OnInit, HostListener, ViewChild, inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Version } from './version';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { VersionkeyFeatureComponent } from './versionkeyFeature/versionkeyFeature.component';
import { ConfirmationDialogComponent } from './../conformationdialogue/conformationdialogue.component';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  providers: [MessageService]
})

export class VersionComponent implements OnInit {

  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;

  version: Version;
  versionData: Version[] = [];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  totallength: number;
  versionCodeList: any = []
  versionFinalData: any = [];
  optionGroupCodeList: any = [];
  showMessage: String;
  responseMsg: string;
  messageType: String;
  isLoadingResults: boolean = false;
  versionDataForPackage: any = [];
  count: number = 0;
  msrpAmount: Number;
  newMsrpAmount: Number;
  freightPriceAmount: Number;
  versionCode: String;
  versionNameEnglish: String;
  versionNameFrench: String;;
  trimNameEnglish: String;;
  trimNameFrench: String;
  newfreightPriceAmount: Number;
  showSpinner: boolean = false;
  showTable: boolean = false;
  @Output() refreshPackage = new EventEmitter<boolean>();
  value: boolean = false;
  countValue: number = 0;
  public columnList = ['action', 'isPackage', 'nciModelCode',
    'optionGroupCode', 'versionCode',
    'versionNameEnglish',
    'trimNameEnglish',
    'versionNameFrench',
    'trimNameFrench',
    'versionMSRPAmount',
    'freightPriceAmount',
    'activeIndicator'];
  userListMatTabDataSource = new MatTableDataSource<Version>(this.versionData);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;


  constructor(private modelService: ModelService, private messageService: MessageService, public dialog: MatDialog) {
    this.version = new Version();
  }

  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    this.userListMatTabDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
     }

  getAlldata() {
    this.versionData = [];
    this.countValue = 0;
    this.modelService.getVersions(this.vehiclesdetails.division, this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(versiondata => {
        if (versiondata["versionVO"] != null) {
          versiondata["versionVO"].forEach(rowData => {
            rowData.id = ++this.countValue;
            if (rowData.activeIndicator == null || rowData.activeIndicator == "" || rowData.activeIndicator == "null") {
              rowData.activeIndicator = "Y";
            }


            this.versionData.push(rowData);
          });
          this.userListMatTabDataSource.data = this.versionData;
          setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
          this.showSpinner = false;
          this.showTable = true;
        }
        else {
          this.showSpinner = false;
          this.showTable = true;
        }

      });
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }

  edited(version, index2, $event) {

    if ($event.checked == false && this.editedData.length > 0) {
      for (let index = 0; index < this.editedData.length; index++) {
        if (version.nciModelCode == this.editedData[index].nciModelCode &&
          version.optionGroupCode == this.editedData[index].optionGroupCode &&
          version.versionMSRPAmount == this.editedData[index].versionMSRPAmount
        ) {
          this.editedData[index].edited = false;
          this.editedData.splice(index, 1);
          // this.msrpAmount= this.editedData[0].versionMSRPAmount;
          // this.freightPriceAmount=this.editedData[0].freightPriceAmount;
          this.getAlldata();

        }

      }


    }
    else {
      version.edited = true;
      this.editedData.push(version);
      this.msrpAmount = this.editedData[0].versionMSRPAmount;
      this.freightPriceAmount = this.editedData[0].freightPriceAmount;

    }

    //console.log(this.editedData);
  }

  save() {

    this.versionCode = this.editedData[0].versionCode;
    this.versionNameEnglish = this.editedData[0].versionNameEnglish;
    this.versionNameFrench = this.editedData[0].versionNameFrench;
    this.trimNameEnglish = this.editedData[0].trimNameEnglish;
    this.trimNameFrench = this.editedData[0].trimNameFrench;
    this.newMsrpAmount = this.editedData[0].versionMSRPAmount;
    this.newfreightPriceAmount = this.editedData[0].freightPriceAmount;

    if (this.msrpAmount != this.newMsrpAmount || this.freightPriceAmount != this.newfreightPriceAmount) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: "There is a difference in MSRP amount between NCI PACE and MCM,are you sure want to Save the new amount in NCI MCM"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.versionCode =="null" && this.versionNameEnglish =="null" && this.versionNameFrench =="null" && this.trimNameEnglish =="null" && this.trimNameFrench =="null") {
            let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
            this.messageService.setMessageInfo(msgObj);
          }
          else {
            this.editedData.forEach(rowData => {

              let version = new Version();
              version.modelKey = rowData.modelKey;
              version.nciModelCode = rowData.nciModelCode;
              version.optionGroupCode = rowData.optionGroupCode;
              version.modelLineCode = this.vehiclesdetails.modelLineCode;
              version.modelYear = this.vehiclesdetails.modelYearNumber;
              if (rowData.edited == true && rowData.isPackage == 'No') {
                version.versionCode = rowData.versionCode;
              }
              else {
                version.versionCode = rowData.nciModelCode;
              }
              version.versionMSRPAmount = rowData.versionMSRPAmount;
              version.freightPriceAmount = rowData.freightPriceAmount;
              version.versionNameEnglish = rowData.versionNameEnglish;
              version.trimNameEnglish = rowData.trimNameEnglish;
              version.versionNameFrench = rowData.versionNameFrench;
              version.trimNameFrench = rowData.trimNameFrench;
              version.versionKey = rowData.versionKey;
              version.activeIndicator = rowData.activeIndicator;
              version.isPackage = rowData.isPackage;
              version.versionDefined = rowData.versionDefined;
              this.versionFinalData.push(version);
              /*  if((rowData.edited==true  && rowData.isPackage=='No') || rowData.versionDefined=="Yes"){
                
                 this.versionFinalData.push(version);
               }
               else{
                
                 this.versionDataForPackage.push(version);
             } */
            });

            if (this.versionFinalData.length > 0) {
              this.showSpinner = true;
              this.showTable = false;
              this.modelService.saveVersionData(this.versionFinalData).subscribe(res => {
                //console.log(this.versionFinalData);
                this.editedData = [];
                this.versionFinalData = [];
                this.responseStatusAdd(res);

                this.getAlldata();

              });
            }
            else {
              let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
              this.messageService.setMessageInfo(msgObj);
            }
          }
        }
      });
    }
    else {

      if (this.versionCode =="null" && this.versionNameEnglish =="null" && this.versionNameFrench =="null" && this.trimNameEnglish =="null" && this.trimNameFrench =="null") {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      } else {
        this.editedData.forEach(rowData => {

          let version = new Version();
          version.modelKey = rowData.modelKey;
          version.nciModelCode = rowData.nciModelCode;
          version.optionGroupCode = rowData.optionGroupCode;
          version.modelLineCode = this.vehiclesdetails.modelLineCode;
          version.modelYear = this.vehiclesdetails.modelYearNumber;
          if (rowData.edited == true && rowData.isPackage == 'No') {
            version.versionCode = rowData.versionCode;
          }
          else {
            version.versionCode = rowData.nciModelCode;
          }
          version.versionMSRPAmount = rowData.versionMSRPAmount;
          version.freightPriceAmount = rowData.freightPriceAmount;
          version.versionNameEnglish = rowData.versionNameEnglish;
          version.trimNameEnglish = rowData.trimNameEnglish;
          version.versionNameFrench = rowData.versionNameFrench;
          version.trimNameFrench = rowData.trimNameFrench;
          version.versionKey = rowData.versionKey;
          version.activeIndicator = rowData.activeIndicator;
          version.isPackage = rowData.isPackage;
          version.versionDefined = rowData.versionDefined;
          this.versionFinalData.push(version);
          /*  if((rowData.edited==true  && rowData.isPackage=='No') || rowData.versionDefined=="Yes"){
            
             this.versionFinalData.push(version);
           }
           else{
            
             this.versionDataForPackage.push(version);
         } */
        });

        if (this.versionFinalData.length > 0) {
          this.showSpinner = true;
          this.showTable = false;
          this.modelService.saveVersionData(this.versionFinalData).subscribe(res => {
            //console.log(this.versionFinalData);
            this.editedData = [];
            this.versionFinalData = [];
            this.responseStatusAdd(res);

            this.getAlldata();

          });
        }
        else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
      }

    }

  }

  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      this.value = true;
      this.refreshPackage.emit(this.value);
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }
  openPopup(version: Version) {
    const dialogRef = this.dialog.open(VersionkeyFeatureComponent, {
      width: '1000px',
      height: '600px',
      data: { version: version, division: this.vehiclesdetails.division }
    });
  }

  ontypeChange(version) {
    if (version.isPackage == "Yes") {
      this.userListMatTabDataSource.data.forEach(row => {
        if (version.nciModelCode == row.nciModelCode) {
          if (row.isPackage == "No" && row.versionCode != "null") {
            this.count++;
            version.versionDefined = "Yes";
            version.edited = false;
            this.editedData.push(version);

          }
        }
      });
      if (this.count <= 0) {
        let msgObj = Object.assign({ "visible": true }, {
          "reponseMsg": "Please create version for the package :"
            + version.nciModelCode
        }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);

      }
      this.count = 0;
    }
    else {
      for (let index = 0; index < this.editedData.length; index++) {
        if (version.nciModelCode == this.editedData[index].nciModelCode &&
          version.optionGroupCode == this.editedData[index].optionGroupCode &&
          version.versionMSRPAmount == this.editedData[index].versionMSRPAmount
        ) {
          this.editedData[index].edited = false;
          this.editedData.splice(index, 1);
        }
      }
    }
  }
}














