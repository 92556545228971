
import { Component, OnInit, Inject, ViewChild, Version, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModelService } from '../../services/model.service';
import { FormControl, Validators, NgForm, FormGroup } from '@angular/forms';
import { MessageService } from '../../services/message.service';



@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  providers: [ MessageService]
})
export class AdminComponent implements OnInit {

  categoryData: any = [];
  Englishname: any = '';
  Frenchname: any = '';
  showSpinner: boolean = false;
  adminForm: FormGroup;
  categoryEnglshName: any = '';
  categoryFrnchName: any = '';
  categoryType: any = '';
  categoryName: any = '';
  messageType :string='';
  responseMsg:string='';
  visibleMsg:boolean=false;
  @ViewChild('adminForm', { static: false }) testForm: NgForm;
  constructor(public dialogRef: MatDialogRef<AdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
    private modelService: ModelService, private messageService: MessageService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.categoryType = this.data.categoryValue;
    if (this.categoryType == "ACC") {
      this.categoryName = "ACCESSORIES"
    }
    else if (this.categoryType == "FEA") {
      this.categoryName = "FEATURES"
    }
    else if (this.categoryType == "SPE") {
      this.categoryName = "SECIFICATIONS"
    }

    console.log(this.data)
    this.initProps();
  }
  initProps() {
    this.adminForm = new FormGroup({
      English: new FormControl('', [Validators.required]),
      French: new FormControl('', [Validators.required]),
    })
   }
  submitForm() {
   if (this.adminForm.valid) {
      let requestValue = {
        "categoryEnglshName": this.adminForm.value.English,
        "categoryFrnchName": this.adminForm.value.French,
        "categoryType": this.data.categoryValue
      }
       this.modelService.saveAdminData(requestValue).subscribe(res => {
        this.responseStatusAdd(res);
       
        this.categoryData = [];
      });
    }
    else {
      this.messageType ="Error";
      this.responseMsg="Please Edit the data and Click on save";
      this.visibleMsg=true;
    }
   }
 

  oncloseModal()
  {
    this.visibleMsg=false;
    if(this.messageType !="Error")
        this.cancel();
  }
  
  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      this.messageType ="Success";
      this.responseMsg=resarr.responseDesc;
      this.visibleMsg=true;
      }
    else {
       this.messageType ="Error";
      this.responseMsg=resarr.responseDesc;
      this.visibleMsg=true;
    }
  }
  

  cancel() {
    this.dialogRef.close();
  }
  validateAllFormFields(formGroup:
    FormGroup) {       
     Object.keys(formGroup.controls).forEach(field=> {  
    const control = formGroup.get(field);          
     if (control instanceof FormControl) {            
    control.markAsTouched({onlySelf: true });
    } else  if (control instanceof FormGroup) {        
    this.validateAllFormFields(control);          
    }
     });
    }

}