export class    PackagekeyFeature{
	id:number;
	equipmentMarketingDescInFrench:string; 
	equipmentMarketingDescInEnglish:string; 
	displayOrderNumber:String;
	displayType :string;
	disclaimerRefCode:string;
	equipmentKey :any;
	modelKey:String;
	edited:boolean=undefined;
	editedCheck:String;
	modelLineCode:String;
	modelYearNumber:String;
	versionKey:String;
}
