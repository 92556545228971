import { Component, OnInit, HostListener, ViewChild, Inject, Input,Output,EventEmitter } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { Exterior } from "./Exterior";
import { FormControl, Validators, NgForm } from '@angular/forms';
import { VehicleDetails } from './../details/details';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { MessageService } from '../services/message.service'
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { numberExists } from '../numberExists.validator';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-exterior-color',
  templateUrl: './exterior-color.component.html',
  styleUrls: ['./exterior-color.component.scss'],
  providers: [MessageService]
})
export class ExteriorColorComponent implements OnInit {
  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;


  exterior: Exterior;
  exteriorData: Exterior[] = [];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  totallength: number;
  versionCodeList: any = []
  versionFinalData: any = [];
  optionGroupCodeList: any = [];
  showMessage: String;
  responseMsg: string;
  messageType: String;
  disclaimerRefCodeData: any = [];
  exteriorFinalData: any = [];
  buttonDisabled: boolean = true;
  count: number = 0;
  availabilityList: any = [];
  showSpinner: boolean = false;
  showTable: boolean = false;
  rowID:number=0;
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  @Output() refreshComplexity = new EventEmitter<boolean>();
  value:boolean=false;

  numberExists: boolean;
  public columnList = ['action',
    'versionCode',
    'colorCode',
    'msrp',
    'colorDescriptionEnglish',
    'colorDescriptionFrench',
    'sortOrdernumber',
    'disclaimerRefCode',
    'availability',
    'activeIndicator'];
  userListMatTabDataSource = new MatTableDataSource<Exterior>(this.exteriorData);
  availableListforDefault = ["OPTIONAL", "DEFAULT"];
  availableListforOptional = ["OPTIONAL"];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;

  constructor(private modelService: ModelService,
    private messageService: MessageService) {
    this.exterior = new Exterior();
  }

  ngOnInit() {
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();

    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
    this.userListMatTabDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }

  getAlldata() {
    this.exteriorData = [];
    //this.availabilityList=['OPTIONAL','DEFAULT'];
    this.rowID=0;
    this.modelService.getExteriorColor(this.vehiclesdetails.versionCode, this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(exteriorData => {
        if (exteriorData["exteriorColorVO"]) {
          exteriorData["exteriorColorVO"].forEach(rowData => {
            rowData.id= ++this.rowID;
            if (rowData.activeIndicator == null || rowData.activeIndicator == "" || rowData.activeIndicator == "null") {
              rowData.activeIndicator = "Y";
            }
            if (rowData.availability == "DEFAULT") {
              this.count++;
            }
           // console.log(this.rowID,rowData.id);
            this.exteriorData.push(rowData);

          });
          setTimeout(() => {
            if (this.count == 1) {
              this.availabilityList = this.availableListforOptional;

            }
            else {
              this.availabilityList = this.availableListforDefault;
            }
            this.userListMatTabDataSource.data = this.exteriorData;

            this.showSpinner = false;
            this.showTable = true;

          });
          setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
        }
        else {
          this.showSpinner = false;
          this.showTable = true;
        }
      });
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }
  onAvailabiltyChange(exterior) {

    if (exterior.availability == 'DEFAULT') {
      this.availabilityList = this.availableListforOptional;
    }
    else {
      this.availabilityList = this.availableListforDefault;
    }

  }

  edited(exterior, index2) {

    if (exterior.editedCheck != null) {
      for (let index = 0; index < this.editedData.length; index++) {
        if (exterior.versionCode == this.editedData[index].versionCode &&
          exterior.optionGrpCode == this.editedData[index].optionGrpCode &&
          exterior.colorCode == this.editedData[index].colorCode && exterior.editedCheck == this.editedData[index].editedCheck) {
          this.editedData[index].edited = false;
          this.editedData[index].editedCheck = null;
          this.editedData.splice(index, 1);
          this.getAlldata();
        }
      }
    }
    else {
      this.getDiclaimeRefCodes();
      exterior.edited = true;
      exterior.editedCheck = index2;
      this.editedData.push(exterior);
    }
  }
  getDiclaimeRefCodes(){
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.division).subscribe(disclaimerRefCodeData =>{
          this.disclaimerRefCodeData=disclaimerRefCodeData;
          this.filteredSearch.next(this.disclaimerRefCodeData.slice());
          this.disclaimerRefcodeSearch.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filteredSearchData();
            });
       });
  }

  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      if (this.editedData.length > 0) {
        this.editedData.forEach(rowData => {
          rowData.edited = false;
          rowData.editedCheck = null;
          this.exteriorFinalData.push(rowData);
        });
        this.showSpinner = true;
        this.showTable = false;
        this.modelService.saveExteriorData(this.exteriorFinalData).subscribe(res => {
          this.responseStatusAdd(res);
          this.getAlldata();
          this.exteriorFinalData = [];
          this.editedData = [];
          this.count = 0;
        });
      }
      else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }
  }




  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000" || resarr.responseCode == "0005") {
      this.value=true;
      this.refreshComplexity.emit(this.value);
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }

    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }

  onSortOrderChange(number, index) {
    let compare=index-1;
    if (number.value != null && number.value != "") {
      for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
        if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && compare!=x) {
          if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
           // this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
            //this.testForm.form.updateValueAndValidity();
            this.testForm.form.controls['sortOrdernumber' + '-' + index].setValidators(numberExists);
            this.testForm.form.controls['sortOrdernumber' + '-' + index].updateValueAndValidity();
          }
        }
      }
    }
  }
  private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;
   
    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
  this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank => 
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }







}




