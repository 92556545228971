import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    private generateTokenUrl:string="https://qa1.services.nissan.ca/token";
    constructor(private httpClient:HttpClient,private authenticationService:AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = localStorage.getItem('access_token');
        if (token!=null) {
            request = request.clone({
                setHeaders: { 
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
        }
        console.log( request + request.url);
        console.log(this.generateTokenUrl);
            if(request.url===this.generateTokenUrl){
                request = request.clone({
                    setHeaders: { 
                        'Content-Type':  'application/x-www-form-urlencoded',
                        'Authorization': 'Basic WXV5WTlCWGJuZ2UyS3FqaE9nUENpTkU1RnNVYTpLWm5tZk1Gb2VqWmRJTkF2aEJ5UVBWMVNfNUFh'
                      //  'Authorization': 'Basic NDVPRWg0QVkxanAwcjhDMWNLVjlibFRsSlNrYTppRDM4VXJyQm1sN0F3VW0xcjV3aDVMUjVUajRh'
                    }
                });
                return next.handle(request);
            }
            else{
                return next.handle(request).pipe(tap(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                        
                        }
                      },
                      (err: any) => {
                        HttpErrorResponse
                        if (err instanceof HttpErrorResponse) {
                            if(err.status===401){
                                this.authenticationService.generateToken();
                                console.log("into this method");
                            }
                           
                        }
                      }
    
                ));
            }
               
            
           
      

    }
    
}