import { Component, OnInit, HostListener, ViewChild, inject, Input, OnChanges } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
//import { ModelComponent } from 'src/app/model/model.component';
//import { Model } from 'src/app/model/model';
import { MatPaginator, MatTableDataSource, MatSort, MatFormField } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { map } from 'rxjs/operators';
import { Accessories } from './accessories';
import { MatAccordion } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
//import { access } from 'fs';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss'],
  providers: [MessageService]
})
export class AccessoriesComponent implements OnInit {

  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;

  accessories: Accessories;
  accessoryData: Accessories[] = [];
  selectedRow: number;
  totallength: number;
  accessoryFinalData: any = [];
  editedRows: Boolean[];
  editedData: any = [];
  public selectecdvalues: String;
  accessoriesSubTypeData: any = [];

  selectall: boolean;
  versions = new FormControl();
  //versionlist:string[];
  versionlist: string;
  listofversions: any[] = [];
 checkedVersionList: string[];
  accessoriesFinalData: any = [];
  disclaimerRefCodeData: any = [];
  showMessage:String;
  responseMsg:string;
  messageType:String;
  public packageList:any=[];
  public selectedList:any=[];
  public columnList = ['action','accessoryType','accessorySubType', 'accessoryCode','partNumber',
    'accessoryEngName', 'accessoryFrnName','installationOption',
    'msrp', 'featureDescriptionEng', 'featureDescriptionFrn', 'mostPopular',
    'versionsAvailability','packageCode','constraintType', 'activeIndicator'];

  userListMatTabDataSource = new MatTableDataSource<Accessories>(this.accessoryData);
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;
  showSpinner: boolean = false;
  showTable: boolean = false;

  constructor(private modelService: ModelService, private messageService: MessageService) {
    this.accessories = new Accessories();
  }

  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
   // this.getAccessoriesSubTypes();
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
    //this.userListMatTabDataSource.sort = this.sort;
    this.selectecdvalues = this.vehiclesdetails.division + " " + this.vehiclesdetails.modelYearNumber + " " + this.vehiclesdetails.modelLineCode;

  }
/*   getDisclaimerData(){
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.division).subscribe(disclaimerRefCodeData => {
        this.disclaimerRefCodeData = disclaimerRefCodeData;
        this.filteredSearch.next(this.disclaimerRefCodeData.slice());
        this.disclaimerRefcodeSearch.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filteredSearchData();
          });
      
      });
  } */
  

  getAlldata() {
     
    this.accessoryData = [];
    let data = [];
    this.modelService.getAccessoriesData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode
    ).subscribe(data => {
      if (data["accessoryVo"].length > 0 && data["accessoryVo"]!=null) {
        data["accessoryVo"].forEach(row => {
          this.listofversions = row.versionsAvailability;
          let versiondata = [];
          row.versionsAvailability.forEach(check => {
            if (check.available == true) {
              versiondata.push(check.versionCode);
            }
          });
          
          row.checkedVersionsList = versiondata;
          console.log( row.checkedVersionsList);
        //  row.accessorySubType=data["accessorySubTypeList"];
          this.accessoryData.push(row);
          setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
          
        

        });
       

      }
      else {
        let tempdata = data["availableVersions"];
        tempdata.forEach(element => {
          let obj = {
            versionCode: element
          }
          this.listofversions.push(obj);
        });
      }
      if (data["accessorySubTypeList"].length > 0 && data["accessorySubTypeList"]!=null)
      {
      let SubTypetempdata = data["accessorySubTypeList"];
      SubTypetempdata.forEach(element => {
        let obj = {
          accessorySubType : element
        }
       
        this.accessoriesSubTypeData.push(obj);
     
        
      });
    }
      this.userListMatTabDataSource.data = this.accessoryData;
      this.showSpinner = false;
      this.showTable = true;
      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
    });
  }
  // getAccessoriesSubTypes()
  // {
  //   this.modelService.getAccessoriesSubTypeData().subscribe(data=>{
  //     //console.log(data);
  //     this.accessoriesSubTypeData = data;
  //     console.log(this.accessoriesSubTypeData);
  //    // console.log(accessoryType);
  //   });  
  // }

  onChanged(i) {
    this.checkedVersionList = i;
    let accessories = new Accessories();
    let selectedList =[];
    let checkedlist = i;
    checkedlist.forEach(element=>{
      let itemList = element.split('_');
      selectedList.push(itemList[1]);
    });
    this.packageList=[];
    console.log(selectedList);
    if(selectedList.length>0){
      this.modelService.getPackages(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode,selectedList).subscribe(packagedata => {
          if(packagedata["packageVO"]!=null){
            this.packageList=packagedata["packageVO"];
          }
        }
        );
    }
    else{
      this.packageList=[];
    }

  }

  selectAllVersions() {
    
    if (this.selectall === false) {
      this.versions = new FormControl();
      return;
    } else if (this.selectall === true) {
      this.versions = new FormControl();
      this.versions.setValue(this.versionlist);
    }
  }
  
  addRow(index) {
  
    //this.getDisclaimerData();
   // this.getAccessoriesSubTypes(); 
    this.packageList=[];
    if (this.userListMatTabDataSource.data != null && this.userListMatTabDataSource.data.length != 0) {
      
    // let index2 = this.accessoryData.indexOf(this.userListMatTabDataSource.filteredData[0]);

      let accessories = new Accessories();
      accessories.checkedVersionsList = [];
      accessories.edited = true;
      accessories.editedCheck ="New";
      accessories.activeIndicator = "Y";
      accessories.mostPopular="0";
      this.accessoryData.splice(index, 0, accessories);
      this.editedData.push(accessories);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.accessoryData;
    }
    else {
      this.accessoryData=[];
      let accessories = new Accessories();
      accessories.edited = true;
      accessories.editedCheck ="New";
      accessories.accessoryType = "";
     accessories.accessorySubType =[];
      accessories.accessoryCode = "";
      accessories.partNumber = "";
      accessories.accessoryEngName = "";
      accessories.accessoryFrnName = "";
      accessories.installationOption = "";
      accessories.msrp = "";
      accessories.featureDescriptionEng = "";
      accessories.featureDescriptionFrn = "";
     // accessories.sortOrdernumber = "";
      accessories.disclaimerRefCode = "";
      accessories.checkedVersionsList = [];
      accessories.activeIndicator = "Y";
      accessories.mostPopular = "0";
      this.accessoryData.push(accessories);
      this.editedData.push(accessories);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.accessoryData;
    }
  }
  applyFilter(filterValue: string) {
   
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    
  }

  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);

    } else {
      let accessories = new Accessories();
      let accessoryVo = [];
      let accessoryList = [];
      let postData = {};
     /*  this.editedData.forEach(rowData => {
        //let splitvalues= rowData.packageCode.split("-");
        if(rowData.edited==true){
        accessories.accessoryType = rowData.accessoryType;
        accessories.accessoryCode = rowData.accessoryCode;
        accessories.accessoryEngName = rowData.accessoryEngName;
        accessories.accessoryFrnName = rowData.accessoryFrnName;
        accessories.installationOption = rowData.installationOption;
        accessories.msrp = rowData.msrp;
        accessories.packageCode=rowData.packageCode;   
        accessories.constraintType=rowData.constraintType,     
        accessories.featureDescriptionEng = rowData.featureDescriptionEng;
        accessories.featureDescriptionFrn = rowData.featureDescriptionFrn;
        accessories.sortOrdernumber = rowData.sortOrdernumber;
        accessories.disclaimerRefCode = rowData.disclaimerRefCode;
        accessories.activeIndicator = rowData.activeIndicator;
        accessories.checkedVersionsList = rowData.checkedVersionsList;
        accessoryVo.push(accessories);
        }
      }); */
     
      if (this.editedData.length > 0) {
        this.editedData.forEach(element => {
          if(element.edited==true){
            //let splitvalues= element.packageCode.split("-");
            let obj = {
              accessoryCode: element.accessoryCode,
              partNumber: element.partNumber,
              accessoryEngName: element.accessoryEngName,
              accessoryFrnName: element.accessoryFrnName,
              accessoryType: element.accessoryType,
             accessorySubType: element.accessorySubType,
              activeIndicator: element.activeIndicator,
              mostPopular: element.mostPopular,
              checkedVersionsList: element.checkedVersionsList,
              disclaimerRefCode: element.disclaimerRefCode,
              featureDescriptionEng: element.featureDescriptionEng,
              featureDescriptionFrn: element.featureDescriptionFrn,
              installationOption: element.installationOption,
              msrp: element.msrp,
              packageCode:element.packageCode,
              constraintType:element.constraintType,
              versionKey:null,
              //sortOrdernumber: element.sortOrdernumber
            };
            accessoryList.push(obj);
          }
         
        });
      }
        if(accessoryList.length>0){
          postData = {
            "modelLineCode": this.vehiclesdetails.modelLineCode,
            "modelYear": this.vehiclesdetails.modelYearNumber,
  
            "accessoryVo": accessoryList
          }
        
          this.showSpinner = true;
          this.showTable = false;
          this.modelService.SaveAccessories(postData).subscribe(res => {
            this.responseStatusAdd(res);
            this.editedData = [];
            //this.accessoriesFinalData=[];          
            this.getAlldata();
          });
        }
        else{
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Add/Edit the record to save the Data" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
       
     
    }
  }

  responseStatusAdd(res) {

    let resarr = res;
    if (resarr.responseCode == "0000") {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }



  rowClick(rowId) {
    this.selectedRow = rowId;
  }
  // onSortOrderChange(Type,number, index) {
    
  //   if (number.value != null && number.value != "") {
  //     for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
  //       if (this.userListMatTabDataSource.data[x].accessoryType == Type)
  //       {
  //       if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && index != x) {
  //         if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
  //           this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
  //           break;
  //         }
  //       }
  //     }
  //     }
  //   }
  // }
  edited(accessories,index2) {
    
    if(accessories.editedCheck!=null && accessories.editedCheck!="New"){
      for(let index = 0;index<this.editedData.length;index++){
        if(this.editedData[index].editedCheck==accessories.editedCheck){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
          this.getAlldata();
        }
      } 
     }
     else if(accessories.editedCheck!=null && accessories.editedCheck=="New"){
      accessories.edited=false;
      accessories.editedCheck=null;
      this.accessoryData.splice(index2,1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.accessoryData;
      setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
     }
     else{ 
      //this.getDisclaimerData();
      accessories.edited = true;
      accessories.editedCheck = index2;
      this.editedData.push(accessories);
      let selectedList=[];
      accessories.checkedVersionsList.forEach(element=>{
        let itemList = element.split('_');
        selectedList.push(itemList[0]);
      });
      this.packageList=[];
      this.modelService.getPackages(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode,selectedList).subscribe(packagedata => {
          if(packagedata["packageVO"]!=null){
            this.packageList=packagedata["packageVO"];
          }
        }
        );
   }
  }

   private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;
   
    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
  this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank => 
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSelectPackageCode(accessory,i){
    if (accessory.packageCode!=null && accessory.packageCode!="") {
      this.testForm.form.get('constraintType' + '-' + i).setErrors({ 'required': 'Required' });
      this.testForm.form.get('constraintType' + '-' + i).updateValueAndValidity();
    }
    else{
      this.testForm.form.get('constraintType' + '-' + i).setErrors({ 'required': '' });
      this.testForm.form.get('constraintType' + '-' + i).clearAsyncValidators();
      this.testForm.form.get('constraintType' + '-' + i).updateValueAndValidity();
    }
  }
}
