export class EimJson {
    modelKey: String;
    modellineCode:  String;
    modelYear:  String;
    eimspeckey: any;
    eimspecposition: any;
    eimspeclength: any;
    eimDescVO:any=[];
    edited: boolean=undefined;
    editedCheck:String;
}