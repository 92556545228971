import { Component, OnInit, HostListener, ViewChild, inject, Input, OnChanges } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from "@angular/material";
import { ModelComponent } from 'src/app/model/model.component';
import { Model } from 'src/app/model/model';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { map } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material';
import { Packages } from './packages';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PackageKeyFeatureComponent } from './package-key-feature/package-key-feature.component';

declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  providers: [MessageService]
})
export class PackagesComponent implements OnInit {

  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;

  package: Packages;
  packageData: Packages[] = [];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  totallength: number;
  packageList: any = [];
  packageFinalData: any = [];
  showMessage: String;
  responseMsg: String;
  messageType: String;
  disclaimerRefCodeData: any = [];
  availabilityList: any = [];
  basedOnStandard: boolean = false;
  showSpinner: boolean = false;
  showTable: boolean = false;
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();

  public columnList = ['action', 'versionCode', 'packageCode',
    'packageDescriptionEnglish',
    'packageDescriptionFrench',
    'sortOrdernumber',
    'msrp',
    'disclaimerRefCode', 'availability',
    'activeIndicator'];

  userListMatTabDataSource = new MatTableDataSource<Packages>(this.packageData);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;


  constructor(private modelService: ModelService, private messageService: MessageService, public dialog: MatDialog) {
    this.package = new Packages();
  }

  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();

    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    this.userListMatTabDataSource.sort = this.sort;



  }
  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }

  getAlldata() {
    this.packageData = [];
    this.modelService.getPackages(this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode,[]).subscribe(packagedata => {
        // this.packageData=packagedata["packageVO"]; 
        if (packagedata["packageVO"] != null) {
          packagedata["packageVO"].forEach(rowData => {
            if (rowData.activeIndicator == 'null' || rowData.activeIndicator == '' || rowData.activeIndicator == null) {
              rowData.activeIndicator = 'Y';
            }
            if (rowData.sortOrdernumber == 'null' || rowData.sortOrdernumber == null) {
              rowData.sortOrdernumber='';
            }
            if (rowData.availability == 'STANDARD' && rowData.activeIndicator == "Y") {
              this.availabilityList = ['OPTIONAL', 'DEFAULT'];
            }
            else {
              this.availabilityList = ['OPTIONAL', 'DEFAULT', 'STANDARD'];
            }

            this.packageData.push(rowData);
          });
          this.userListMatTabDataSource.data = this.packageData;
          this.showSpinner = false;
          this.showTable = true;
          setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
        }
        else {
          this.showSpinner = false;
          this.showTable = true;
        }
      });

  }

  disclaimerCodePackage() {
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.division).subscribe(disclaimerRefCodeData => {
        this.disclaimerRefCodeData = disclaimerRefCodeData;
        this.filteredSearch.next(this.disclaimerRefCodeData.slice());
        this.disclaimerRefcodeSearch.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filteredSearchData();
          });
      });
  }

  edited(packages, index2, $event) {
    this.availabilityList = ['OPTIONAL', 'DEFAULT'];
    if (packages.availability == 'STANDARD' && packages.activeIndicator == "Y") {
      packages.edited = false;
    }
    else if ($event.checked == false && this.editedData.length > 0) {
      for (let index = 0; index < this.editedData.length; index++) {
        if (packages.msrp == this.editedData[index].msrp && packages.availability == this.editedData[index].availability && packages.packageDescriptionEnglish == this.editedData[index].packageDescriptionEnglish && packages.sortOrdernumber == this.editedData[index].sortOrdernumber) {
          this.editedData[index].edited = false;
          this.editedData.splice(index, 1);
          this.getAlldata();

        }
      }
    }
    else {
      this.disclaimerCodePackage();
      packages.edited = true;
      this.editedData.push(packages);

    }
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }


  openPopup(packages: Packages) {

    const dialogRef = this.dialog.open(PackageKeyFeatureComponent, {
      width: '1000px',
      height: '600px',
      data: { packages: packages,division:this.vehiclesdetails.division }
    });


  }




  save() {
    if ((this.testForm.touched && this.editedData.length < 0 ) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      this.editedData.forEach(rowData => {
        if (rowData.packageDescriptionEnglish != null && rowData.packageDescriptionFrench != null) {
          let packages = new Packages();
          packages.modelLineCode = this.vehiclesdetails.modelLineCode;
          packages.modelYear = this.vehiclesdetails.modelYearNumber;
          packages.versionCode = this.vehiclesdetails.versionCode;
          packages.versionKey = rowData.versionKey;
          packages.optionGrpCode = rowData.optionGrpCode;
          packages.packageCode = rowData.packageCode;
          packages.packageDescriptionEnglish = rowData.packageDescriptionEnglish;
          packages.packageDescriptionFrench = rowData.packageDescriptionFrench;
          packages.msrp = rowData.msrp;
          packages.activeIndicator = rowData.activeIndicator;
          packages.equipmentKey = rowData.equipmentKey;
          packages.sortOrdernumber = rowData.sortOrdernumber;
          packages.disclaimerRefCode = rowData.disclaimerRefCode;
          packages.availability = rowData.availability;
          this.packageFinalData.push(packages);
        }

      });
      if (this.packageFinalData.length > 0) {
        this.showSpinner = true;
        this.showTable = false;
        this.modelService.savePackages(this.packageFinalData).subscribe((res) => {
          this.responseStatusAdd(res);
          this.getAlldata();
          this.editedData = [];
          this.packageFinalData = [];
        });
      }
      else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }

  }


  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "12113" });
      this.messageService.setMessageInfo(msgObj);
    }
  }
  onSortOrderChange(number, index) {
    if (number.value != null && number.value != "") {
      for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
        if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && index != x) {
          if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
            this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
            break;
          }
        }
      }
    }
  }
  private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;
   
    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
  this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank => 
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


}
