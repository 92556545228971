import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Model } from '../model/model';
import { Version } from '../version/version';
import { Disclaimer } from '../dislcaimer/disclaimer';
import { Interior } from '../interior-colour/Interior';
import { HttpHeaders } from '@angular/common/http';
import { Time } from '@angular/common';
import { version } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { observable, Observable } from 'rxjs';
import { request } from 'https';


@Injectable({
  providedIn: 'root'
})
export class ModelService {

  // private CONSTANT_URL="http://localhost:9980/mcm-service/vehicle"
  private CONSTANT_URL="https://qa1.services.nissan.ca/mcm-service/1.0";
  //private CONSTANT_URL = "http://localhost:8080/vehicle";
  //Commonurls

  //Commonurls
  private SERVER_URL_GET_MODELYEAR = this.CONSTANT_URL + "/getModelYears?";
  private SERVER_URL_GET_MODELS = this.CONSTANT_URL + "/getModelLineCodes?";
  private SERVER_URL_GET_VERSIONCODES = this.CONSTANT_URL + "/getVersionCodes?";
  private SERVER_URL_GET_DISCLAIMERREFCODE = this.CONSTANT_URL + "/getDisclaimerRefCodes?";

  //Model
  private SERVER_URL_GET_VEHICLE_MODEL = this.CONSTANT_URL + "/model/getModels?";
  private SERVER_URL_POST_SAVE_MODEL = this.CONSTANT_URL + "/model/saveModels";


  //Version
  private SERVER_URL_GET_VEHICLE_VERSION = this.CONSTANT_URL + "/version/getVersions?";
  private SERVER_URL_POST_SAVE_VERSION = this.CONSTANT_URL + "/version/saveVersions";

  //versionKeyFeatuer
  private SERVER_URL_GET_VERSIONKEYFEATURE = this.CONSTANT_URL + "/version/getVersionMarketingFeatures?";
  private SERVER_URL_POST_SAVE_VERSIONKEYFEATURE = this.CONSTANT_URL + "/version/saveVersionMarketingFeature";
  private SERVER_URL_DELETE_VERSIONKEYFEATURE = this.CONSTANT_URL + "/version/deleteVersionMarketingFeature";



  //Package
  private SERVER_URL_GET_PACKAGES = this.CONSTANT_URL + "/package/getPackages?";
  private SERVER_URL_POST_SAVE_PACKAGES = this.CONSTANT_URL + "/package/savePackages";

  //package key feature
  private SERVER_URL_GET_PACKAGEKEYFEATURE = this.CONSTANT_URL + "/package/getPackageMarketingFeature?";
  private SERVER_URL_POST_SAVE_PACKAGEKEYFEATURE = this.CONSTANT_URL + "/package/savePackageMarketingFeature";
  private SERVER_URL_DELETE_PACKAGEKEYFEATURE = this.CONSTANT_URL + "/package/deletePackageMarketingFeature?"


  //Disclaimer

  //Disclaimers	http://10.36.126.19:9980/marketingcontent-service/vehicle/disclaimers/deleteDisclaimers
  private SERVER_URL_GET_DISCLAIMER = this.CONSTANT_URL + "/disclaimers/getDisclaimers?";
  private SERVER_URL_POST_DISCLAIMER_ADD = this.CONSTANT_URL + "/disclaimers/saveDisclaimers";

  //Exterior Color
  private SERVER_URL_GET_EXTERIORCOLOR = this.CONSTANT_URL + "/colors/getExteriorColors?";
  private SERVER_URL_POST_SAVE_EXTERIORCOLOR = this.CONSTANT_URL + "/colors/saveExteriorColors";


  //interior
  private SERVER_URL_GET_INTERIORCOLOR = this.CONSTANT_URL + "/colors/getInteriorColors?";
  private SERVER_URL_POST_SAVE_INTERIORCOLOR = this.CONSTANT_URL + "/colors/saveInteriorColors";




  //upholstery
  private SERVER_URL_GET_UPHOLSTERYDATA = this.CONSTANT_URL + "/colors/getUpholstery?";
  private SERVER_URL_POST_SAVE_UPHOLSTERYDATA = this.CONSTANT_URL + "/colors/saveUpholstery";

  //accessories
  private SERVER_URL_GET_ACCESSORIES = this.CONSTANT_URL + "/accessories/getAccessories?"
  private SERVER_URL_POST_SAVE_ACCESSORIES = this.CONSTANT_URL + "/accessories/saveAccessories";

  //Specifications

  private SERVER_URL_GET_SPECIFICATION = this.CONSTANT_URL + "/specifications/getSpecifications?"
  private SERVER_URL_POST_SAVE_SPECIFICATION = this.CONSTANT_URL + "/specifications/saveSpecifications";

  //Features

  private SERVER_URL_GET_FEATURES = this.CONSTANT_URL + "/features/getFeatures?"
  //private SERVER_URL_PUT_UPDATE_FEATURES = this.CONSTANT_URL+"/features/features";
  private SERVER_URL_POST_SAVE_FEATURES = this.CONSTANT_URL + "/features/saveFeatures";
  private SERVER_URL_GET_FEATURES_TYPES = this.CONSTANT_URL + "/features/getFeatureTypes";
  private SERVER_URL_GET_Accessories_SUBTYPES = this.CONSTANT_URL + "/accessories/getAccessorySubType";
  private SERVER_URL_GET_FEATURES_SUBTYPES = this.CONSTANT_URL + "/features/getFeatureSubTypes?";
  private SERVER_URL_GET_SPECIFICATION_TYPES = this.CONSTANT_URL + "/specifications/getSpecificationTypes";
  private SERVER_URL_GET_SPECIFICATION_SUBTYPES = this.CONSTANT_URL + "/specifications/getSpecificationSubTypes?";

  //EIM
  private SERVER_URL_GET_EIMDATA = this.CONSTANT_URL + "/eim/getEIMData?";
  private SERVER_URL_SAVE_EIMDATA = this.CONSTANT_URL + "/eim/saveEIMData";

  //Complexity
  private SERVER_URL_GET_COMPLEXITY_DATA = this.CONSTANT_URL + "/complexity/getColorDetails?";
  private SERVER_URL_RESET_COMPLEXITY_DATA = this.CONSTANT_URL + "/complexity/resetColorConstraints?";
  private SERVER_URL_SAVE_COMPLEXITY_DATA = this.CONSTANT_URL + "/complexity/saveComplexityColors";
  private SERVER_URL_GET_ADMIN_DATA = this.CONSTANT_URL + "/admin";

  private SERVER_URL_POST_SAVE_ADMINDATA = this.CONSTANT_URL + "/admin";
  private SERVER_URL_GET_PARTNUMBERS = this.CONSTANT_URL + "/partNumberList?";
  private SERVER_URL_GET_CONSTRAINTS = this.CONSTANT_URL + "/constraints?";
  private SERVER_URL_SAVE_CONSTRAINTS_DATA = this.CONSTANT_URL + "/constraints";
  private SERVER_URL_DELETE_CONSTRAINTS_DATA = this.CONSTANT_URL + "/constraints?"
  private SERVER_URL_GET_NEW_COMPLEXITY  = this.CONSTANT_URL + "/complexity/getColorDetails?";;
  private SERVER_URL_SAVE_NEW_COMPLEXITY= this.CONSTANT_URL + "/complexity/saveComplexityColors";
  private SERVER_URL_RESET_COMPLEXITY = this.CONSTANT_URL + "/complexity/resetColorConstraints?";
  private SERVER_URL_GET_ALL_NEW_COMPLEXITY = this.CONSTANT_URL + "/complexity/getColorDetails?";
  



  

  constructor(private httpClient: HttpClient) { }

  public getModelYears(division) {


    return this.httpClient.get(this.SERVER_URL_GET_MODELYEAR + "division=" + division);
  }

  public getModelsByYear(division, year) {

    return this.httpClient.get(this.SERVER_URL_GET_MODELS + "division=" + division + "&modelYear=" + year);
  }

  public getVersionCode(modelYear, division, model) {

    return this.httpClient.get(this.SERVER_URL_GET_VERSIONCODES + "modelYear=" + modelYear +
      "&division=" + division + "&modelLineCode=" + model);
  }

  //Models
  public getModels(division, modelYearNumber, modelLineCode) {

    return this.httpClient.get(this.SERVER_URL_GET_VEHICLE_MODEL + "modelYear=" + modelYearNumber + "&division=" + division
      + "&modelLineCode=" + modelLineCode);
  }

  public saveModelData(modelData: any) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_MODEL, modelData);
  }
  //version

  public getVersions(division, modelYear, modelLineCode) {

    return this.httpClient.get(this.SERVER_URL_GET_VEHICLE_VERSION + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&division=" + division);
  }
  public saveVersionData(versionData: any) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_VERSION, versionData);
  }

  public getDisclaimerRefCode(modelYear: String, division: String) {

    return this.httpClient.get(this.SERVER_URL_GET_DISCLAIMERREFCODE + "modelYear=" + modelYear
      + "&division=" + division);
  }

  //Exterior
  public getExteriorColor(versionCode: String, modelYear: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_EXTERIORCOLOR + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&versionCode=" + versionCode);
  }

  public saveExteriorData(exteriorData: any) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_EXTERIORCOLOR, exteriorData);
  }

  //package
  public getPackages(modelYear, modelLineCode, versionCode, trims) {


    return this.httpClient.get(this.SERVER_URL_GET_PACKAGES + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&versionCode=" + versionCode + "&trims=" + trims);

  }

  public savePackages(packageData: any[]) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_PACKAGES, packageData);

  }

  //Disclaimers
  public  getDisclaimersData(division: String, modelLineCode: String, modelYearNumber: String) {

    return  this.httpClient.get(this.SERVER_URL_GET_DISCLAIMER + "division=" + division + "&modelLineCode=" + modelLineCode + "&modelYearNumber="
      + modelYearNumber);
  }

  public  SaveDisclaimer(disclaimer: Disclaimer) {

    return  this.httpClient.post(this.SERVER_URL_POST_DISCLAIMER_ADD, disclaimer);
  }

  //version Key Feature
  public getVersionkeyFeatureData(modelkey, versionkey) {

    return  this.httpClient.get(this.SERVER_URL_GET_VERSIONKEYFEATURE + "modelKey=" + modelkey + "&versionKey=" + versionkey);
  }

  public saveVersionkeyFeatureData(versionkeyFeatureData) {

    return  this.httpClient.post(this.SERVER_URL_POST_SAVE_VERSIONKEYFEATURE, versionkeyFeatureData);
  }

  public deleteversionkeyFeature(versionkey, sortOrderNumber) {

    return this.httpClient.delete(this.SERVER_URL_DELETE_VERSIONKEYFEATURE + "?versionKey=" + versionkey + "&SortOrderNumber=" + sortOrderNumber);
  }


  // interior 

  public getInteriorColor(versionCode: String, modelYear: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_INTERIORCOLOR + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&versionCode=" + versionCode);
  }

  public saveInteriorData(interiorData: any) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_INTERIORCOLOR, interiorData);
  }


  //package key feature


  public getPackagekeyFeatureData(modelYear: String, modelLineCode: String, equipmentKey: String) {

    return this.httpClient.get(this.SERVER_URL_GET_PACKAGEKEYFEATURE + "modelLineCode=" + modelLineCode + "&modelYearNumber=" +
      modelYear + "&equipmentKey=" + equipmentKey);
  }


  public savePackagekeyFeatureData(packagekeyFeatureData) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_PACKAGEKEYFEATURE, packagekeyFeatureData);
  }

  public deletePackagekeyFeature(equipmentKey, sortOrderNumber) {

    return this.httpClient.delete(this.SERVER_URL_DELETE_PACKAGEKEYFEATURE + "equipmentKey=" + equipmentKey + "&SortOrderNumber=" + sortOrderNumber);
  }


  //upholstery
  public getUpholsteryData(versionCode: String, modelYear: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_UPHOLSTERYDATA + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&versionCode=" + versionCode);
  }

  public saveUpholsteryData(upholsteryData) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_UPHOLSTERYDATA, upholsteryData);
  }


  public getAccessoriesData(modelYearNumber: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_ACCESSORIES + "modelYearNumber=" + modelYearNumber + "&modelLineCode=" + modelLineCode);
    // return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/accessories/getAccessories?"+"modelYearNumber="+modelYearNumber+"&modelLineCode="+modelLineCode);
  }



  public SaveAccessories(accessories) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_ACCESSORIES, accessories);
    // return this.httpClient.post("http://localhost:9980/mcm-service/vehicle/accessories/saveAccessories",accessories);
  }

  public getspecificationData(modelYear: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_SPECIFICATION + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode)

    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/specifications/getSpecifications?"+"modelYear="+modelYear
    //      +"&modelLineCode="+modelLineCode+"&versionCode="+versionCode);


  }
  public saveSpecificationData(specifications) {

    return this.httpClient.post(this.SERVER_URL_POST_SAVE_SPECIFICATION, specifications);
    // return this.httpClient.post("http://localhost:9980/mcm-service/vehicle/specifications/saveSpecifications",specifications);

  }

  public getFeaturesData(modelYearNumber: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_FEATURES + "modelYearNumber=" + modelYearNumber + "&modelLineCode=" + modelLineCode);
    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/features/getFeatures?"+"modelYearNumber="+modelYearNumber+"&modelLineCode="+modelLineCode);
  }

  public SaveFeatures(feature) {
    //console.log(feature);
    return this.httpClient.post(this.SERVER_URL_POST_SAVE_FEATURES, feature);
    //    return this.fr.post("http://localhost:9980/mcm-service/vehicle/features/saveFeatures",feature);
  }
  // public updateFeatures(feature){
  //   //console.log(feature);
  //  return this.httpClient.post(this.SERVER_URL_PUT_UPDATE_FEATURES,feature); 
  // //    return this.fr.post("http://localhost:9980/mcm-service/vehicle/features/saveFeatures",feature);
  // }
  public getSpecificationType() {
    return this.httpClient.get(this.SERVER_URL_GET_SPECIFICATION_TYPES);
  }
  public getSpecificationSubType(type) {
    return this.httpClient.get(this.SERVER_URL_GET_SPECIFICATION_SUBTYPES + "type=" + type);
    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/specifications/getSpecificationsSubTypes?type="+type);
  }

  public getFeaturesTypeData() {
    return this.httpClient.get(this.SERVER_URL_GET_FEATURES_TYPES);
    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/features/getFeatureTypes");
  }
  public getAccessoriesSubTypeData() {
    return this.httpClient.get(this.SERVER_URL_GET_Accessories_SUBTYPES);
    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/features/getFeatureTypes");
  }
  public getFeaturesSubTypeData(type: String) {
    return this.httpClient.get(this.SERVER_URL_GET_FEATURES_SUBTYPES + "type=" + type);
    //  return this.httpClient.get("http://localhost:9980/mcm-service/vehicle/features/getFeatureSubTypes?type="+type);
  }

  public getEIMData(division, modelYear, modelLineCode) {

    return this.httpClient.get(this.SERVER_URL_GET_EIMDATA + "modelYear=" + modelYear
      + "&modelLineCode=" + modelLineCode + "&division=" + division);
  }

  public saveEIMData(eimdata) {

    return this.httpClient.post(this.SERVER_URL_SAVE_EIMDATA, eimdata);
  }
  public getComplexityData(modelLineCode, modelYear, versionCode) {

    return this.httpClient.get(this.SERVER_URL_GET_COMPLEXITY_DATA + "modelYear=" + modelYear
      + "&modelCode=" + modelLineCode + "&versionCode=" + versionCode);
  }

  public resetComplexityData(modelLineCode, modelYear, versionCode) {
    return this.httpClient.delete(this.SERVER_URL_RESET_COMPLEXITY_DATA + "modelYear=" + modelYear
      + "&modelCode=" + modelLineCode + "&versionCode=" + versionCode);
  }
  public saveComplexityData(requestData) {

    return this.httpClient.post(this.SERVER_URL_SAVE_COMPLEXITY_DATA, requestData);
  }
  public getAdminData(type): Observable<any> {

    return this.httpClient.get(this.SERVER_URL_GET_ADMIN_DATA + "?ctgryType=" + type);
  }


  public saveAdminData(adminData) {
    debugger;
    return this.httpClient.post(this.SERVER_URL_POST_SAVE_ADMINDATA, adminData);
  }

  public getPartNumberData(modelYearNumber: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_PARTNUMBERS + "modelYear=" + modelYearNumber + "&modelLineCode=" + modelLineCode);
  }
  public getConstraintsData(modelYearNumber: String, modelLineCode: String) {

    return this.httpClient.get(this.SERVER_URL_GET_CONSTRAINTS + "modelYear=" + modelYearNumber + "&modelLineCode=" + modelLineCode);
  }
  public saveConstraintsData(requestData) {

    return this.httpClient.post(this.SERVER_URL_SAVE_CONSTRAINTS_DATA, requestData);
  }
  // public deleteRule(requestData) {
  //   return this.httpClient.delete(this.SERVER_URL_DELETE_CONSTRAINTS_DATA, requestData);
  // }
  public deleteRule(modelLineCode, modelYear,partNumber,constraintCode,mappedPartNumber) {
    
    return this.httpClient.delete(this.SERVER_URL_DELETE_CONSTRAINTS_DATA+ "modelLineCode=" + modelLineCode +"&modelYear=" + modelYear +"&partNumber=" +partNumber+"&constraintCode=" +constraintCode+ "&mappedPartNumber="+ mappedPartNumber);

  }
  
  public getNewComplexityData(modelLineCode, modelYear, versionCode) {
    return this.httpClient.get(this.SERVER_URL_GET_NEW_COMPLEXITY + "modelYear=" + modelYear
      + "&modelCode=" + modelLineCode + "&versionCode=" + versionCode);
      
  }
  public saveNewComplexityData(requestData) {

    return this.httpClient.post(this.SERVER_URL_SAVE_NEW_COMPLEXITY, requestData);
  }
  
  public resetComplexity(modelLineCode, modelYear, versionCode,constraint,equipmentCode,mappedEquipmentCode) {
    return this.httpClient.delete(this.SERVER_URL_RESET_COMPLEXITY + "modelYear=" + modelYear
      + "&modelCode=" + modelLineCode + "&versionCode=" + versionCode+ "&constraint=" + constraint+ "&equipmentCode="+ equipmentCode+"&mappedEquipmentCode="+mappedEquipmentCode);
  }
  public getAllNewComplexityData( modelYear,modelCode, versionCode,pageIndicator) {
    return this.httpClient.get(this.SERVER_URL_GET_ALL_NEW_COMPLEXITY + "modelYear=" + modelYear
      + "&modelCode=" + modelCode + "&versionCode=" + versionCode+ "&pageIndicator=" +pageIndicator);
  }

 




}
