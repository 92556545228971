import { Component, OnInit, HostListener, ViewChild, inject, Input, OnChanges } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort, MatFormField } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { map, elementAt } from 'rxjs/operators';
import { Features } from './features';
import { MatAccordion } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { element } from 'protractor';


declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  providers: [MessageService]
})
export class FeaturesComponent implements OnInit {

  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;

  features: Features;
  featureData: Features[] = [];
  selectedRow: number;
  totallength: number;
  // accessoryFinalData:any=[];
  editedRows: Boolean[];
  editedData: any = [];
  public selectecdvalues: String;

  listofversions: any[] = [];
  checkedVersionList: string[];
  disclaimerRefCodeData: any = [];
  featuresTypeData: any = [];
  featuresSubTypeData: any = [];
  public setFeatureCode: String;
  showSpinner: boolean = false;
  showTable: boolean = false;
  subtypedata: any[] = [];
  shortForm: String;
  featureCode: any;
  showMessage: String;
  responseMsg: string;
  messageType: String;
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  public packageList: any = [];

  public columnList = ['action', 'featureType', 'featureCode',
    'featureEngName', 'featureFrnName', 'catCode', 'sortOrdernumber'
    , 'versionsAvailability', 'packageCode', 'constraintType', 'activeIndicator'];


  userListMatTabDataSource = new MatTableDataSource<Features>(this.featureData);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;
  sortOrderNumbers: any = [];
  checkedVersionsList: any;
  constructor(private modelService: ModelService, private messageService: MessageService) {
    this.features = new Features();
  }

  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
  //  this.userListMatTabDataSource.sort = this.sort;
    this.selectecdvalues = this.vehiclesdetails.division + " " + this.vehiclesdetails.modelYearNumber + " " + this.vehiclesdetails.modelLineCode;

  }

  getDislaimeRefCodes() {
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.division).subscribe(disclaimerRefCodeData => {
        this.disclaimerRefCodeData = disclaimerRefCodeData;
        this.filteredSearch.next(this.disclaimerRefCodeData.slice());
        this.disclaimerRefcodeSearch.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filteredSearchData();
          });
      });
  }

  

  getFeatureTypes() {
    this.modelService.getFeaturesTypeData().subscribe(data => {
      //console.log(data);
      this.featuresTypeData = data;
      console.log( this.featuresTypeData);
    });
  }


  getAlldata() {

    this.featureData = [];
    let data = [];

    this.getDislaimeRefCodes();
    this.getFeatureTypes();

    this.modelService.getFeaturesData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode
    ).subscribe(data => {
      if (data["featuresVo"].length > 0) {
        data["featuresVo"].forEach(row => {
          this.listofversions = row.versionsAvailability;
          let versiondata = [];
          row.versionsAvailability.forEach(check => {
            if (check.available == true) {
              versiondata.push(check.versionCode);
            }
          });
          row.checkedVersionsList = versiondata;
          this.sortOrderNumbers.push(row.sortOrdernumber);
          if (row.sortOrdernumber != "") {
            this.sortOrderNumbers.push(row.sortOrdernumber);
          }
          this.featureData.push(row);
          // this.checkedVersionList = row.checkedVersionsList;

        });
        this.userListMatTabDataSource.data = this.featureData;
        this.showSpinner = false;
        this.showTable = true;
        setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
      }
      else {
        this.showSpinner = false;
        this.showTable = true;
        let tempdata = data["availableVersions"];
        tempdata.forEach(element => {
          let obj = {
            versionCode: element
          }
          this.listofversions.push(obj);
        });
      }

    });

  }

  addRow(index) {

    this.getDislaimeRefCodes();
    this.getFeatureTypes();
    this.packageList = [];
    if (this.userListMatTabDataSource.data != null && this.userListMatTabDataSource.data.length != 0) {

      let index2 = this.featureData.indexOf(this.userListMatTabDataSource.filteredData[0]);
      let feature = new Features();
      feature.checkedVersionsList = this.listofversions;
      feature.edited = true;
      feature.editedCheck = "New";
      feature.editedValue = true;
      feature.featureCode = null;
      feature.activeIndicator = "Y";
      this.featureData.splice(index, 0, feature);
      this.editedData.push(feature);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.featureData;
      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
    else {
      this.featureData = [];
      let feature = new Features();
      feature.edited = true;
      feature.editedValue = true;
      feature.editedCheck = "New";
      feature.featureType = "";
      // feature.featureSubType = "";
      feature.featureCode = null;
      feature.featureEngName = "";
      feature.featureFrnName = "";
      feature.sortOrdernumber = "";
      feature.disclaimerRefCode = "";
      feature.checkedVersionsList = this.listofversions;
      feature.activeIndicator = "Y";
      this.featureData.push(feature);
      this.editedData.push(feature);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.featureData;
    }
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    
  }

  OnTypeChange(i) {
    let feature = new Features();
    let type = i;
    //console.log(type);

    this.modelService.getFeaturesSubTypeData(type).subscribe(subtypedata => {
      //console.log(subtypedata);
      this.featuresSubTypeData = subtypedata;
      //console.log(this.featuresSubTypeData);
    });
  }

  getShortForm(type, subtype) {
    // let feature = new Features();
    // let subtypedata:any[]=[];

    this.modelService.getFeaturesSubTypeData(type).subscribe(subtypedata => {
      //console.log(subtypedata);
      this.featuresSubTypeData = subtypedata;
      //console.log(this.featuresSubTypeData);      
    });
    this.featuresSubTypeData.forEach(element => {
      //console.log(element.name+ " "+ element.shortForm);
      if (element.name == subtype) {
        this.shortForm = element.shortForm;
        //console.log(this.shortForm);
      }

    });
    return this.shortForm;
  }

  // OnSubTypeChange(i) {
  //   let feature = new Features();
  //   //console.log(i);
  //   switch (i) {
  //     case "ENGINE":
  //       this.setFeatureCode = "EN";
  //       break;

  //     case "DRIVETRAIN":
  //       this.setFeatureCode = "DT";
  //       break;

  //     case "BRAKES":
  //       this.setFeatureCode = "BR";
  //       break;

  //     case "SUSPENSION/STEERING":
  //       this.setFeatureCode = "SS";
  //       break;

  //     case "WHEELS/TIRES":
  //       this.setFeatureCode = "WT";
  //       break;

  //     case "EXTERIOR FEATURES":
  //       this.setFeatureCode = "EF";
  //       break;

  //     case "COMFORT/CONVENIENCE":
  //       this.setFeatureCode = "CC";
  //       break;

  //     case "SEATING/APPOINTMENTS":
  //       this.setFeatureCode = "SA";
  //       break;

  //     case "AUDIO/ENTERTAINMENT":
  //       this.setFeatureCode = "AE";
  //       break;

  //     case "SAFETY":
  //       this.setFeatureCode = "ST";
  //       break;
  //   }
  //   //console.log(this.setFeatureCode);
  // }


  save() {
    let setfeatureCode: String;
  //update features
    if (this.featureCode != null) {
      if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {

        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);

      }
      else {
        let feature = new Features();
        let featuresVo = [];
        let postData = {};
        let postData1 = {};
        let featureList = [];


        if (this.editedData.length > 0) {
          this.editedData.forEach(element => {
            if (element.edited == true) {
              element.editedCheck = null;
              element.edited = false;
              console.log(element.packageCode);
              //let splitvalues= element.packageCode.split("-");
              let obj = {
                featureType: element.featureType,
                //   featureSubType: element.featureSubType,    
                versionKey: element.versionKey,
                featureCode: element.featureCode,
                featureEngName: element.featureEngName,
                featureFrnName: element.featureFrnName,
                sortOrdernumber: element.sortOrdernumber,
                disclaimerRefCode: element.disclaimerRefCode,
                checkedVersionsList: element.checkedVersionsList,
                packageCode: element.packageCode,
                catCode: element.catCode,
                constraintType: element.constraintType,
                activeIndicator: element.activeIndicator,
                //  shortForm: this.getShortForm(element.featureType,element.featureSubType)
              };
              featureList.push(obj);
            }

          });
        }
        if (featureList.length > 0) {
          postData = {
            "modelLineCode": this.vehiclesdetails.modelLineCode,
            "modelYear": this.vehiclesdetails.modelYearNumber,
            "featuresVo": featureList
          }
          this.showSpinner = true;
          this.showTable = false;
          this.modelService.SaveFeatures(postData).subscribe(res => {
            this.responseStatusAdd(res);
            this.editedData = [];
            this.getAlldata();
          });

        }
        else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Add/Edit the record to save the Data" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
      }


    }
    //Save Features
    else {
      if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {

        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);

      }
      else {
        let feature = new Features();
        let featuresVo = [];
        let postData = {};
        let postData1 = {};
        let featureList = [];


        if (this.editedData.length > 0) {
          this.editedData.forEach(element => {
            if (element.edited == true) {
              element.editedCheck = null;
              element.edited = false;
              console.log(element.packageCode);
              //let splitvalues= element.packageCode.split("-");
              let obj = {
                featureType: element.featureType,
                //   featureSubType: element.featureSubType,    

                // featureCode: element.featureCode,
              
                featureEngName: element.featureEngName,
                featureFrnName: element.featureFrnName,
                sortOrdernumber: element.sortOrdernumber,
                disclaimerRefCode: element.disclaimerRefCode,
                checkedVersionsList: element.checkedVersionsList,
                packageCode: element.packageCode,
                catCode: element.catCode,
                constraintType: element.constraintType,
                activeIndicator: element.activeIndicator,
                //  shortForm: this.getShortForm(element.featureType,element.featureSubType)
              };
              featureList.push(obj);
            }

          });
        }
        if (featureList.length > 0) {
          postData = {
            "modelLineCode": this.vehiclesdetails.modelLineCode,
            "modelYear": this.vehiclesdetails.modelYearNumber,
            "featuresVo": featureList
          }

          this.showSpinner = true;
          this.showTable = false;


          this.modelService.SaveFeatures(postData).subscribe(res => {
            this.responseStatusAdd(res);
            this.editedData = [];
            this.getAlldata();
          });




        }
        else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Add/Edit the record to save the Data" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
      }

    }

  }

  responseStatusAdd(res) {

    let resarr = res;
    if (resarr.responseCode == "0000") {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }

  edited(feature, index2, $event) {
    //console.log(feature.featureSubType + " " + index2 + " " + $event);
    // if edited but doesn't want to save.
    this.featureCode = feature.featureCode;
    if (feature.editedCheck != null && feature.editedCheck != "New") {
      for (let index = 0; index < this.editedData.length; index++) {
        if (this.editedData[index].editedCheck == feature.editedCheck) {
          this.editedData[index].edited = false;
          this.editedData[index].editedCheck = null;
          this.editedData.splice(index, 1);
          this.getAlldata();
        }
      }
    }
    // if clicked but don't want to enter data and to remove empty rows 
    else if (feature.editedCheck != null && feature.editedCheck == "New") {
      feature.edited = false;

      feature.editedCheck = null;
      this.featureData.splice(index2, 1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.featureData;
      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
    else {
      this.getDislaimeRefCodes();
      // for editing
      feature.edited = true;
      feature.editedCheck = index2;
      this.editedData.push(feature);
      let selectedList = [];
      feature.checkedVersionsList.forEach(element => {
        let itemList = element.split('--');
        selectedList.push(itemList[0].split('_')[1]);
      });
      this.packageList = [];
      this.modelService.getPackages(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode, selectedList).subscribe(packagedata => {
          if (packagedata["packageVO"] != null) {
            this.packageList = packagedata["packageVO"];
          }
        }
        );
    }


    //  feature.edited = true;
    //feature.editedValue = false;
    //this.editedData.push(feature);

    // this.OnTypeChange(feature.featureType);

  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }
  // item:string="";
  // tempItem:string="";
  onChanged(i, feature, indexValue) {

    let checkedlist = [];
    let tempList = [];
    let version = "";
    let selectedList = [];
    checkedlist = i;

    checkedlist.forEach(element => {
      let itemList = element.split('--');
      selectedList.push(itemList[0].split('_')[1]);
    });
    this.packageList = [];
    console.log(selectedList);
    if (selectedList.length > 0) {
      this.modelService.getPackages(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode, selectedList).subscribe(packagedata => {
          if (packagedata["packageVO"] != null) {
            this.packageList = packagedata["packageVO"];
          }
        }
        );
    }
    else {
      this.packageList = [];
    }

    if (checkedlist.length > 0) {
      for (let i = 0, j = 0; i < checkedlist.length, j < feature.checkedVersionsList.length; i++ , j++) {
        let itemList = checkedlist[i].split('--');
        selectedList.push(itemList[0]);
        if (version != itemList[0])
          tempList.push(itemList[0]);
        else {
          feature.checkedVersionsList.splice(j, 1);
          this.testForm.form.get('checkedVersionsList-' + indexValue).setValue(feature.checkedVersionsList);
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "The " + version + " is already selected" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
          break;
          //return false;

        }
        version = itemList[0];
        // tempList=checkedlist.filter(item)
      }


      return true;
    }



  }
  onSortOrderChange(feature, number, index) {
    if (number.value != null && number.value != "") {
      for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
        if (this.userListMatTabDataSource.data[x].featureType == feature) {
          if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && index != x) {
            if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
              this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
              break;
            }
          }
        }
      }
    }
  }
  // onSortOrderChange(number, index) {
  //   if (number.value != null && number.value != "") {
  //     for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
  //       if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && index != x) {
  //         if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
  //           this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;

    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank =>
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSelectPackageCode(feature, i) {
    if (feature.packageCode != null && feature.packageCode != "") {
      this.testForm.form.get('constraintType' + '-' + i).setErrors({ 'required': 'Required' });


    }
    else {
      this.testForm.form.get('constraintType' + '-' + i).setErrors({ 'required': '' });
      this.testForm.form.get('constraintType' + '-' + i).clearAsyncValidators();
      this.testForm.form.get('constraintType' + '-' + i).updateValueAndValidity();
    }
  }
}

