import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {OverlayModule} from 'angular-io-overlay';
import  { MessageService} from '../app/services/message.service';
import{MatSortModule} from '@angular/material/sort'
import { MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatOptionModule,MatRadioModule,
  MatSelectModule,
  MatMenu,
  MatMenuModule} from '@angular/material';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTableModule} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';

import { HomeComponent } from './home/home.component';
import { ModelComponent } from './model/model.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { VersionComponent } from './version/version.component';

import {MessageComponent  } from './message/message.component'
import { DisclaimerComponent } from '../app/dislcaimer/disclaimer.component';

import {InteriorColourComponent} from './interior-colour/interior-colour.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { VehicleDetailsComponent } from './details/details.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ExteriorColorComponent } from './exterior-color/exterior-color.component';
import { NumberDirective } from './numberOnly.directive';
import { VersionkeyFeatureComponent } from '../app/version/versionkeyFeature/versionkeyFeature.component';
import { PackagesComponent } from './packages/packages.component';

import {MatTooltipModule} from '@angular/material/tooltip';

import { ConfirmationDialogComponent } from './conformationdialogue/conformationdialogue.component';
import { EIMJsonComponent } from './eimjson/eimjson.component';
import { UpholsteyComponent } from './upholstery/upholstery.component';
import { PackageKeyFeatureComponent } from './packages/package-key-feature/package-key-feature.component';

import { SpecificationComponent } from './specification/specification.component';
import { FeaturesComponent } from './features/features.component';
import { AuthInterceptor } from './services/authInterceptor';
import  { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { EIMDescriptionComponent } from './eimjson/eimdescription/eimdescription.component';
import { ComplexityComponent } from './complexity/complexity.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { AdminComponent } from './settings/admin/admin.component';
import { RulesComponent } from './rules/rules.component';

import { ComplexityNewComponent } from './complexity-new/complexity-new.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModelComponent,
    VersionComponent,
    DisclaimerComponent,
    InteriorColourComponent,
    AccessoriesComponent,
    VehicleDetailsComponent,
    MessageComponent,
    ExteriorColorComponent,
    NumberDirective,
    VersionkeyFeatureComponent,
    PackagesComponent,
    SpecificationComponent,
    ConfirmationDialogComponent,
    UpholsteyComponent,
    PackageKeyFeatureComponent,
    FeaturesComponent,
    EIMJsonComponent,EIMDescriptionComponent,
    MatSelectSearchComponent,
    ComplexityComponent,
    LoginComponent,
    SettingsComponent,
    AdminComponent,
    RulesComponent,
    ComplexityNewComponent

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule, 
    MatMenuModule,
    MatRadioModule,
    MatExpansionModule,
    
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatCheckboxModule,MatTooltipModule,
    MDBBootstrapModule.forRoot(),

  ],
  providers: [MessageService,
    
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
   
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    VersionkeyFeatureComponent,ConfirmationDialogComponent,PackageKeyFeatureComponent,EIMDescriptionComponent,AdminComponent
  ]
 })
export class AppModule { }
