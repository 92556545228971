import { Router } from '@angular/router';
import { MatAccordion } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { version } from 'punycode';
import { Settings } from './settings';
import { VersionComponent } from '../version/version.component';
import { AuthenticationService } from '../services/authentication.service';
import { Component, OnInit, ViewChild, Input, OnChanges, AfterViewInit, Output, EventEmitter,Inject} from '@angular/core';
import { ModelService } from '../services/model.service';
import { Model } from '../model/model';
import { VehicleDetails } from '../details/details';
import { MessageService } from '../services/message.service'
import { map } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Accessories } from '../accessories/accessories';
import { ConfirmationDialogComponent } from './../conformationdialogue/conformationdialogue.component';
import { Disclaimer } from '../dislcaimer/disclaimer';
import { AdminComponent } from '../settings/admin/admin.component';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [MessageService]
})
export class SettingsComponent implements OnInit {
  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;
  showSpinner: boolean = false;
  public showAccessories: boolean = true;
  @Output() refreshVersion = new EventEmitter<boolean>();
  details: boolean = true;
  isAccessories: boolean = false;
  status: any = '';
  isFeatures: boolean = false;
  isSpecifications: boolean = false;
  categoryValue: any = '';
  public columnList = ['categoryEnglshName', 'categoryFrnchName', 'categoryActiveIn'];
  categoryData: Settings[] = [];
  settings: Settings;
  CategoryDataSource = new MatTableDataSource<Settings>(this.categoryData);
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  rowID: number = 0;
  statusIndicator: boolean = true;
  messageType: string = '';
  responseMsg: string = '';
  visibleMsg: boolean = false;
  isCategorgDSLoaded: boolean = true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('testForm', { static: false }) testForm: NgForm;
  constructor(private modelService: ModelService, private messageService: MessageService, public dialog: MatDialog,
    ) {
    this.settings = new Settings();
  }
  ngOnInit() {
    this.showSpinner = true;
    setTimeout(() => this.CategoryDataSource.paginator = this.paginator);


  }
  getAllData(type) {
    this.categoryData = [];
    this.isCategorgDSLoaded = false;
    // this.CategoryDataSource.data=this.categoryData;
    this.modelService.getAdminData(type).subscribe(categoryData => {
      if (categoryData != null) {
        this.CategoryDataSource.data = categoryData;
      }
      setTimeout(() => this.CategoryDataSource.paginator = this.paginator);
      console.log(categoryData);
      this.isCategorgDSLoaded = true;
    },
      // err=>{
      //   let res = {
      //     responseDesc:"unable to connect server.....please try again",
      //     responseCode:"1111"
      //    }
      //    this.responseStatusAdd(res);
      //    }
    );
  }

  openPopup(categoryValue) {
    const dialogRef = this.dialog.open(AdminComponent, {
      width: '500px',
      // height:'300px',
      panelClass: 'custom-modalbox',
      data: { categoryValue }
      
    }).afterClosed().subscribe(result => {
       this.getAllData(this.categoryValue);
       });
}
selectCategory(selectCategory) {
    this.categoryData = [];
    this.CategoryDataSource.data = this.categoryData;
    this.categoryValue = selectCategory.target.value;
    this.isAccessories = (this.categoryValue == "ACC");
    this.isFeatures = (this.categoryValue == "FEA");
    this.isSpecifications = (this.categoryValue == "SPE");
    this.getAllData(this.categoryValue);

  }

  selectStatus(settings) {
    this.isCategorgDSLoaded = true;
    this.submitForm(settings);
    console.log(settings);
    this.getAllData(this.categoryValue);

  }
  submitForm(Settings) {
    if(Settings!=0)
    {
    this.status = Settings;
    this.modelService.saveAdminData(Settings).subscribe(res => {
      this.responseStatusAdd(res);
      this.getAllData(this.categoryValue);
      this.categoryData = [];
    });
     }
  else 
  {
      this.messageType ="Error";
      this.responseMsg="Please Change the Status";
      this.visibleMsg=true;
    }

  }
   
   rowClick(rowId) {
    this.selectedRow = rowId;
  }
  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      this.messageType = "Success";
      this.responseMsg = "Status Saved Successfully";
      this.visibleMsg = true;
    }
    else {
      this.messageType = "Error";
      this.responseMsg = resarr.responseDesc;
      this.visibleMsg = true;
    }
  }
  oncloseModal()
  {
  this.visibleMsg=false;
  }
 
  
}
