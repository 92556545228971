export class VersionkeyFeature{
	id:number;
	versionFeatureDescInEnglish:string; 
	versionFeatureDescInFrench:string; 
	displayOrderNumber:any;
	displayType :string;
	disclaimerRefCode:string;
	versionKey:any;
	modelKey:any;
	edited:boolean=undefined;
	editedCheck:String;
}