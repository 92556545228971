import { Component, OnInit, HostListener, ViewChild, Inject, Input } from '@angular/core';
import { VehicleDetails } from './../details/details';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort, MatFormField } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { MessageService } from '../services/message.service'
import { map, elementAt } from 'rxjs/operators';
import { Rules } from './rules';
import { MatAccordion } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { element } from 'protractor';
import { ConfirmationDialogComponent } from  '../conformationdialogue/conformationdialogue.component';


declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
  providers: [MessageService]
})
export class RulesComponent implements OnInit {
  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;
  selectedRow: number;
  totallength: number;
  editedRows: Boolean[];
  editedData: any = [];
  partData: any = [];
  ruleData: Rules[] = [];
  partNumber: any = [];
  mappedPartNumber: any = [];
  constraintCode: any = [];
   modelLineCode: any = [];
    modelYear: any = [];
    rowID:number=0;
      constraintsData: any = [];
  public selectecdvalues: String;
  PartNumberData: any = [];
  PartNumberData1: any = [];
  showSpinner: boolean = false;
  showTable: boolean = false;
  showMessage: String;
  responseMsg: string;
  messageType: String;
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  public packageList: any = [];
  public columnList = ['action', 'PartNumber', 'dependency', 'PartNumber1'];
  userListMatTabDataSource = new MatTableDataSource<Rules>(this.ruleData);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;
  sortOrderNumbers: any = [];
  checkedVersionsList: any;
  constructor(private modelService: ModelService, private messageService: MessageService, public dialog: MatDialog) {
  }


  ngOnInit() {
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getPartNumbers();
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    this.selectecdvalues = this.vehiclesdetails.division + " " + this.vehiclesdetails.modelYearNumber + " " + this.vehiclesdetails.modelLineCode;

  }

  OnRuleChange(value) {
    if (value) {
      this.PartNumberData1 = this.PartNumberData.filter(item => item != value)
    }
    else {
      this.PartNumberData1 = this.PartNumberData;
    }
  }
  getPartNumbers() {
    this.modelService.getPartNumberData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode).subscribe(data => {
      this.PartNumberData = data;
      console.log(this.PartNumberData);
    });
  }
  addRow(index) {
    this.getPartNumbers();
    this.packageList = [];
    if (this.userListMatTabDataSource.data != null && this.userListMatTabDataSource.data.length != 0) {
      let index2 = this.ruleData.indexOf(this.userListMatTabDataSource.filteredData[0]);
      let rule = new Rules();
      rule.edited = true;
      rule.editedCheck = "New";
      rule.id=++this.rowID;
      rule.editedValue = true;
      this.ruleData.splice(index, 0, rule);
      this.editedData.push(rule);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.ruleData;

      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
    else {
      this.ruleData = [];
      let rule = new Rules();
      rule.edited = true;
      rule.editedValue = true;
      rule.editedCheck = "New";
      rule.partNumber = "";
      rule.dependency = "";
      rule.partNumber1 = "";

      this.ruleData.push(rule);
      this.editedData.push(rule);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.ruleData;
    }
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
  }
  
  deleteRule(ruleData){
    if(ruleData.editedCheck== "New")
    {
      this.getAlldata();

    }
    else
  {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you confirm the deletion of this data?"
    });
    dialogRef.afterClosed().subscribe(result => {
    if(result) {
    let rule = new Rules();
    let ruleList = [];
    let ruleListVo = [];
    let postData = {};
    ruleData.edited=false;
    ruleData.editedCheck=null;
    this.userListMatTabDataSource.data = [];
    if(this.ruleData.length==1)
    {
      this.userListMatTabDataSource.data = [];

    }
    else
    {
      this.userListMatTabDataSource.data = this.ruleData;

    }
     
      setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
      this.partNumber= ruleData.partNumber,
      this.mappedPartNumber=ruleData.mappedPartNumber,
      this.constraintCode= ruleData.constraintCode,

      this.modelLineCode= this.vehiclesdetails.modelLineCode,
      this.modelYear=this.vehiclesdetails.modelYearNumber,
      
      this.modelService.deleteRule(this.modelLineCode, this.modelYear,this.partNumber,this.constraintCode,this.mappedPartNumber).subscribe(res=>{
     
        this.responseStatusAdd(res);
    
      this.getAlldata();
    });
  }
       
      });
    }
    }
  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let rule = new Rules();
      let ruleList = [];
      let ruleListVo = [];
      let postData = {};
      let postData1 = {};
      if (this.editedData.length > 0) {
        this.editedData.forEach(element => {
          if (element.edited == true) {
            element.editedCheck = null;
            element.edited = false;
            let obj = {
              partNumber: element.partNumber,
              mappedPartNumber: element.partNumber1,
              constraintCode: element.dependency,
            };
            ruleList.push(obj);

          }

        });
      }
      if (ruleList.length > 0) {
        postData = {
          "modelLineCode": this.vehiclesdetails.modelLineCode,
          "modelYear": this.vehiclesdetails.modelYearNumber,
          "constraintsData": ruleList
        }
        this.showSpinner = true;
        this.showTable = false;
        this.modelService.saveConstraintsData(postData).subscribe(res => {
          this.responseStatusAdd(res);
          this.getAlldata();
          this.editedData = [];
        });
      }
      else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Add/Edit the record to save the Data" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }
  }
  getAlldata() {
    this.rowID=0;
    this.ruleData = [];
    let data = [];
    this.modelService.getConstraintsData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode
    ).subscribe(data => {
      if(data!=null)
      {
     if (data["constraintsData"].length > 0) {
        data["constraintsData"].forEach(rowData => {
        //  rowData.id= ++this.rowID;
          this.ruleData.push(rowData);
        });
        this.userListMatTabDataSource.data = this.ruleData
        this.showSpinner = false;
        this.showTable = true;
        setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
      }
    }
      else{
        this.showSpinner=false;
       this.showTable=true; 
    } 
    });
  }
  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {

      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }
  
  rowClick(rowId) {
    this.selectedRow = rowId;
  }
}
