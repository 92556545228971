export class Features{    
    featureType:String;
    featureSubType:  String;
    featureCode:String;
    featureEngName:String;
    featureFrnName:String;    
    modelYearNumber:  String;
    modelLineCode:  String;
    sortOrdernumber:String;
    versionKey:any;
    packageCode:String;
    constraintType:String;
    catCode:String;
    disclaimerRefCode:String;
    selectVersions:any[];
    checkedVersionsList:any[];
    versionsAvailability:any[];
    activeIndicator:  String;
    edited: boolean=undefined;
    editedValue:boolean = undefined;
    editedCheck:String;
    }