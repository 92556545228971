import { Component, OnInit , Inject,ViewChild,} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModelService } from '../../services/model.service';
import { FormControl, Validators,NgForm } from '@angular/forms';
import { MessageService } from '../../services/message.service'
import { PackagekeyFeature } from './packagekeyFeature';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from  './../../conformationdialogue/conformationdialogue.component';
import { Packages } from '../packages';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-package-key-feature',
  templateUrl: './package-key-feature.component.html',
  styleUrls: ['./package-key-feature.component.scss'],
  providers: [MessageService]
})



export class PackageKeyFeatureComponent implements OnInit {


  packagekeyFeatureData: PackagekeyFeature[]=[];
  selectedRow: number;
  editedRows: Boolean[];
  editedData: any=[];
  disclaimerRefCodeData:any=[];
  showMessage: String;
  responseMsg: string;
  messageType: String;
  showSpinner:boolean = false;
  showTable:boolean = false;
 packagekeyFeatureFinalData:any=[];
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('testForm',{static:false}) testForm: NgForm;
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  rowID:number=0;

  constructor(public dialogRef: MatDialogRef<PackageKeyFeatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modelService: ModelService,private messageService:MessageService,public dialog: MatDialog) {
}



public columnList = ['action',
'displayOrderNumber',
'disclaimerRefCode',
'equipmentMarketingDescInEnglish', 
'equipmentMarketingDescInFrench' ];
userListMatTabDataSource = new MatTableDataSource<PackagekeyFeature>(this.packagekeyFeatureData);





  ngOnInit() {

    this.editedRows = [];
    this.editedData = [];
    this.showSpinner=true;
          this.showTable=false;
    this.getAlldata();
    this.editedRows = [];
  
  }



  getAlldata() {
    this.rowID=0;
    this.packagekeyFeatureData=[];
    //console.log("----->"+this.data["packages"])
   this.modelService.getPackagekeyFeatureData(this.data["packages"].modelYear,this.data["packages"].modelLineCode,this.data["packages"].equipmentKey).subscribe(packagekeyFeatureData=>{
        // this.packagekeyFeatureData=packagekeyFeaturedata["packageMarketingFeatureVO"];
          if(packagekeyFeatureData["equipmentMarketingDescriptionVO"]!=null){
            packagekeyFeatureData["equipmentMarketingDescriptionVO"].forEach(rowData => {
              rowData.id= ++this.rowID;
              this.packagekeyFeatureData.push(rowData);
            });
          this.userListMatTabDataSource.data=this.packagekeyFeatureData; 
          this.showSpinner=false;
          this.showTable=true; 
        
          setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
         
       }
       else{
        this.showSpinner=false;
       this.showTable=true; 
    } 
       
    });
}


getDiclaimeRefCodes(){
  this.modelService.getDisclaimerRefCode(this.data["packages"].modelYear,
      this.data.division).subscribe(disclaimerRefCodeData =>{
        this.disclaimerRefCodeData=disclaimerRefCodeData;
        this.filteredSearch.next(this.disclaimerRefCodeData.slice());
        this.disclaimerRefcodeSearch.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filteredSearchData();
          });
     });
}

addRow() {

  if(this.userListMatTabDataSource.data !=null && this.userListMatTabDataSource.data.length!=0)
  {
    this.getDiclaimeRefCodes();
    let packagekeyFeature = new PackagekeyFeature();
    packagekeyFeature.edited=true; 
    packagekeyFeature.id=++this.rowID;
    packagekeyFeature.equipmentKey=this.data["packages"].equipmentKey;
    packagekeyFeature.modelLineCode=this.data["packages"].modelLineCode;
    packagekeyFeature.modelYearNumber=this.data["packages"].modelYear;
   
    packagekeyFeature.editedCheck="New";
    this.packagekeyFeatureData.splice(0, 0, packagekeyFeature);
    this.editedData.push(packagekeyFeature);
    this.userListMatTabDataSource.data = [];
    this.userListMatTabDataSource.data = this.packagekeyFeatureData;
    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
  }
  else 
  {
        this.getDiclaimeRefCodes();
        this.packagekeyFeatureData=[];
        let packagekeyFeature = new PackagekeyFeature();
        packagekeyFeature.edited=true; 
        packagekeyFeature.id=1;
        this.rowID=1;
        packagekeyFeature.disclaimerRefCode="";
        packagekeyFeature.displayOrderNumber="";
        packagekeyFeature.equipmentMarketingDescInEnglish="";
        packagekeyFeature.equipmentMarketingDescInFrench="";
        packagekeyFeature.displayType="";
        // packagekeyFeature.modelKey=this.data["packages"].modelKey;
        packagekeyFeature.modelLineCode=this.data["packages"].modelLineCode;
       packagekeyFeature.modelYearNumber=this.data["packages"].modelYear;
        packagekeyFeature.equipmentKey=this.data["packages"].equipmentKey;
        packagekeyFeature.editedCheck="New";
        this.packagekeyFeatureData.push(packagekeyFeature);
        this.editedData.push(packagekeyFeature);
        this.userListMatTabDataSource.data = [];
        this.userListMatTabDataSource.data = this.packagekeyFeatureData;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
  }
}

applyFilter(filterValue: string) {
   this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }

rowClick(rowId) {
  this.selectedRow = rowId;
}

edited(packagekeyFeature,index2) {
 
  if(packagekeyFeature.editedCheck!=null && packagekeyFeature.editedCheck!="New"){
      for(let index = 0;index<this.editedData.length;index++){
        if(packagekeyFeature.editedCheck==this.editedData[index].editedCheck){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
        }
      } 
     }
     else{
      this.getDiclaimeRefCodes();
      packagekeyFeature.edited = true;
      packagekeyFeature.editedCheck= index2;
      this.editedData.push(packagekeyFeature);
     }    
}


save() {
  if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
  let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
  this.messageService.setMessageInfo(msgObj);

  } else {
          this.editedData.forEach(row=>{
              if(row.edited==true){
                  this.packagekeyFeatureFinalData.push(row);
              }
          });
          this.showSpinner=true;
          this.showTable=false;
          if(this.packagekeyFeatureFinalData.length>0){
              this.modelService.savePackagekeyFeatureData(this.editedData).subscribe(res=>{
                  this.responseStatusAdd(res);
                  this.getAlldata();
                  this.editedData=[];
              });
          }
          else{
              let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
              this.messageService.setMessageInfo(msgObj); 
          }
        
  }

}

cancel(){
  this.dialogRef.close();
}


responseStatusAdd(res) {
  let resarr = res;
  if (resarr.responseCode == "0000") {
  
      let msgObj = Object.assign({ "visible": true }, {"reponseMsg":  resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
  }
  else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
  }
}


deletePackagekeyFeature(packagekeyFeature,index2){
  if(packagekeyFeature.editedCheck!=null && packagekeyFeature.editedCheck=="New"){
       
      for(let x=0;x<this.packagekeyFeatureData.length;x++){
        if(index2==this.packagekeyFeatureData[x].id){
            this.packagekeyFeatureData.splice(x,1);
        }
    }
    for(let x=0;x<this.editedData.length;x++){
        if(index2==this.editedData[x].id){
            this.editedData.splice(x,1);
        }
    }
        this.userListMatTabDataSource.data = [];
        this.userListMatTabDataSource.data = this.packagekeyFeatureData;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
     }
    
     else{
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: "Do you confirm the deletion of this data?"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            packagekeyFeature.edited=false;
            packagekeyFeature.editedCheck=null;
            for(let x=0;x<this.packagekeyFeatureData.length;x++){
              if(index2==this.packagekeyFeatureData[x].id){
                  this.packagekeyFeatureData.splice(x,1);
              }
          }
              this.userListMatTabDataSource.data = [];
              this.userListMatTabDataSource.data = this.packagekeyFeatureData;
              setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
              setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
            this.modelService.deletePackagekeyFeature(packagekeyFeature.equipmentKey,packagekeyFeature.displayOrderNumber).subscribe(res=>{
              this.responseStatusAdd(res);
          });
          }
        });
     
     }
}
onSortOrderChange(number, index) {
  if (number.value != null && number.value != "") {
    for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
          if (this.userListMatTabDataSource.data[x].displayOrderNumber != null &&   this.userListMatTabDataSource.data[x].id!=index &&
            this.userListMatTabDataSource.data[x].displayOrderNumber == number.value ) {
              this.testForm.form.get('displayOrderNumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
              break;
          }
    }
  }
}
private filteredSearchData() {
  if (!this.disclaimerRefCodeData) {
    return;
  }
  // get the search keyword
  let search = this.disclaimerRefcodeSearch.value;
 
  if (!search) {
    this.filteredSearch.next(this.disclaimerRefCodeData.slice());
    return;
  } else {
    search = search.toLowerCase();
  }
  // filter the banks
this.filteredSearch.next(
    this.disclaimerRefCodeData.filter(bank => 
      bank.toLowerCase().indexOf(search) > -1)
  );
}
ngOnDestroy() {
  this._onDestroy.next();
  this._onDestroy.complete();
}




}
