import { Component, OnInit, HostListener, ViewChild, Inject, Input } from '@angular/core';
import { VehicleDetails } from './../details/details';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatTableDataSource, MatSort, MatFormField } from '@angular/material';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_VALUE_ACCESSOR } from '@angular/material';
import { MessageService } from '../services/message.service'
import { map, elementAt } from 'rxjs/operators';
import { } from './../details/details';
//import { Complexity } from "./Complexity";
import { MatAccordion } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { element } from 'protractor';
import { ConfirmationDialogComponent } from '../conformationdialogue/conformationdialogue.component';
import { ComplexityNew } from './complexityNew';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-complexity-new',
  templateUrl: './complexity-new.component.html',
  styleUrls: ['./complexity-new.component.scss'],
  providers: [MessageService]
})
export class ComplexityNewComponent implements OnInit {
  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;
  selectedRow: number;
  totallength: number;
  editedRows: Boolean[];
  listofcolors: any[] = [];
  editedData: any = [];

  complexity: ComplexityNew;

  complexityData: ComplexityNew[] = [];
  public selectecdvalues: String;
  colorComplexityData: any = [];
  colorComplexityData1: any = [];
  showSpinner: boolean = false;
  showTable: boolean = false;
  showMessage: String;
  responseMsg: string;
  messageType: String;
  rowID: number = 0;
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  public packageList: any = [];
  public columnList = ['action', 'ColorComplexity', 'dependency', 'ColorComplexity1'];
  userListMatTabDataSource = new MatTableDataSource<ComplexityNew>(this.complexityData);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;
  sortOrderNumbers: any = [];
  checkedVersionsList: any;
  constructor(private modelService: ModelService, private messageService: MessageService,
    public dialog: MatDialog) {
  }
  ngOnInit() {
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getColorComplexity();
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    //  this.selectecdvalues = this.vehiclesdetails.division + " " + this.vehiclesdetails.modelYearNumber + " " + this.vehiclesdetails.modelLineCode;
  }
  OnComplexityChange(value) {
    if (value) {
      this.colorComplexityData1 = this.colorComplexityData.filter(item => item != value)
    }
    else {
      this.colorComplexityData1 = this.colorComplexityData;
    }
  }
  getColorComplexity() {
    this.colorComplexityData = [];
    this.responseMsg = "";
    this.modelService.getNewComplexityData(this.vehiclesdetails.modelLineCode, this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.versionCode
    ).subscribe(data => {
      if (data["colorsList"].length > 0) {
        data["colorsList"].forEach(row => {
          this.colorComplexityData.push(row.id);
        });
        console.log(this.colorComplexityData);
      }
      else {
        this.showSpinner = false;
        this.showTable = true;
      }

    });
  }
  getAlldata() {
    this.rowID = 0;
    this.responseMsg = "";

    this.complexityData = [];
    let data = [];
    let pageIndicator = 'Y';

    this.modelService.getAllNewComplexityData(this.vehiclesdetails.modelYearNumber, this.vehiclesdetails.modelLineCode, this.vehiclesdetails.versionCode, pageIndicator
    ).subscribe(data => {
      if (data["constaintsData"].length > 0 && data["constaintsData"] != null) {
        data["constaintsData"].forEach(rowData => {
          //  rowData.id= ++this.rowID;
          this.complexityData.push(rowData);
        });

        this.userListMatTabDataSource.data = this.complexityData
        this.showSpinner = false;
        this.showTable = true;
        setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
      }
      else {
        this.showSpinner = false;
        this.showTable = true;
      }

    });
  }
  addRow(index) {
    this.getColorComplexity();
    this.packageList = [];
    if (this.userListMatTabDataSource.data != null && this.userListMatTabDataSource.data.length != 0) {
      let index2 = this.complexityData.indexOf(this.userListMatTabDataSource.filteredData[0]);
      let complexity = new ComplexityNew();

      complexity.edited = true;
      complexity.editedCheck = "New";
      complexity.id = ++this.rowID;
      complexity.editedValue = true;
      this.complexityData.splice(index, 0, complexity);
      this.editedData.push(complexity);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.complexityData;

      setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(() => this.userListMatTabDataSource.sort = this.sort);
    }
    else {
      this.complexityData = [];
      let complexity = new ComplexityNew();
      complexity.edited = true;
      complexity.editedValue = true;
      complexity.editedCheck = "New";
      complexity.colorComplexity = "";
      complexity.dependency = "";
      complexity.colorComplexity1 = "";
      this.complexityData.push(complexity);
      this.editedData.push(complexity);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.complexityData;
    }
  }
  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteComplexity(complexityData) {
    if (complexityData.editedCheck == "New") {
      this.getAlldata();

    }
    else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "Do you confirm the deletion of this data?"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let complexity = new ComplexityNew();
          let complexityList = [];
          let complexityListVo = [];
          let postData = {};
          complexityData.edited = false;
          complexityData.editedCheck = null;
          this.userListMatTabDataSource.data = [];
          if (this.complexityData.length == 1) {
            this.userListMatTabDataSource.data = [];
          }
          else {
            this.userListMatTabDataSource.data = this.complexityData;

          }
          // this.userListMatTabDataSource.data = this.complexityData;
          setTimeout(() => this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(() => this.userListMatTabDataSource.sort = this.sort);

          this.modelService.resetComplexity(this.vehiclesdetails.modelLineCode, this.vehiclesdetails.modelYearNumber,
            this.vehiclesdetails.versionCode, complexityData.constraintCode, complexityData.equipMentCode, complexityData.mappedPartNumber).subscribe(res => {

              this.responseStatusAdd(res);


              this.getAlldata();
            });
        }

      });
    }
  }
  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let complexity = new ComplexityNew();
      let complexityList = [];
      let complexityListVo = [];
      let postData = {};
      let complexityPostData = [];
      let postData1 = {};
      if (this.editedData.length > 0) {
        this.editedData.forEach(element => {
          if (element.edited == true) {
            element.editedCheck = null;
            element.edited = false;
            postData = {
              "modelCode": this.vehiclesdetails.modelLineCode,
              "modelYear": this.vehiclesdetails.modelYearNumber,
              "versionCode": this.vehiclesdetails.versionCode,
              "constraint": element.dependency,
              "equipmentCode": element.colorComplexity,
              "mappedEquipmentCode": element.colorComplexity1
            };
            //  rulePostData.push(postData);
          }
        });

      }
      this.showSpinner = true;
      this.showTable = false;
      this.modelService.saveNewComplexityData(postData).subscribe(res => {
        this.responseStatusAdd(res);
        this.responseMsg = "";

        this.editedData = [];
        this.getAlldata();
      });


    }
  }

  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }
  rowClick(rowId) {
    this.selectedRow = rowId;
  }


}
