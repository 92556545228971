import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


/* const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  };
   */

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private generateTokenUrl:string="https://qa1.services.nissan.ca/token";
   // private generateAuthUrl:string="https://as.dev.na.nissan.biz/SecureAuth931";
   
    private generateTokenBody:any="grant_type=client_credentials";
    constructor(private httpClient:HttpClient) { }
 
   /*  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>; */

 /*    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    } */

 /*    public get apiToken(): any {
        return localStorage.getItem('access_token');
    } */
    // public getSecure()
    // {
      
    //   return this.httpClient.get(this.generateAuthUrl);
    // }

    generateToken():any {
        //Get token
        let token=null;
         const httpOptions = {
           headers: new HttpHeaders({
             'Content-Type':  'application/x-www-form-urlencoded',
          //   'Authorization': 'Basic NDVPRWg0QVkxanAwcjhDMWNLVjlibFRsSlNrYTppRDM4VXJyQm1sN0F3VW0xcjV3aDVMUjVUajRh'
             'Authorization': 'Basic WXV5WTlCWGJuZ2UyS3FqaE9nUENpTkU1RnNVYTpLWm5tZk1Gb2VqWmRJTkF2aEJ5UVBWMVNfNUFh'
             

             
           })
         };
         //console.log(httpOptions);
          return this.httpClient.post(this.generateTokenUrl,this.generateTokenBody,httpOptions).subscribe(response=>{
          token = response;
          //console.log(token);
          localStorage.setItem('access_token', JSON.stringify(token.access_token));
          });
    }
       

   
}