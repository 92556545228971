import { Component, OnInit, HostListener, ViewChild, Inject, Input } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../services/message.service'
import { Upholstery } from "./upholstery";
import { FormControl, Validators, NgForm } from '@angular/forms';
import { VehicleDetails } from './../details/details';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-upholstery',
  templateUrl: './upholstery.component.html',
  styleUrls: ['./upholstery.component.scss'],
  providers: [MessageService]
})
export class UpholsteyComponent {
  @Input("vehiclesdetails") vehiclesdetails: VehicleDetails;


  upholsteryData: Upholstery[] = [];

  selectedRow: number;
  editedRows: Boolean[];
  editedData: any = [];
  totallength: number;
  versionCodeList: any = [];
  versionFinalData: any = [];
  optionGroupCodeList: any = [];
  showMessage: String;
  responseMsg: string;
  messageType: String;
  disclaimerRefCodeData: any = [];
  upholsteryFinalData: any = [];
  buttonDisabled: boolean = true;
  count: number = 0;
  availabilityList: any = [];
  showSpinner: boolean = false;
  showTable: boolean = false;
  public disclaimerRefcodeSearch: FormControl = new FormControl();
  public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  rowID:number=0;


  public columnList = ['action',
    'versionCode',
    'colorCode',
    'msrp',
    'colorDescriptionEnglish',
    'colorDescriptionFrench',
    'sortOrdernumber',
    'disclaimerRefCode',
    'availability',
    'activeIndicator'];



  userListMatTabDataSource = new MatTableDataSource<Upholstery>(this.upholsteryData);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('testForm', { static: false }) testForm: NgForm;

  constructor(private modelService: ModelService, private messageService: MessageService) {
  }

  ngOnInit() {
    //console.log(this.vehiclesdetails);
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    this.getAlldata();
    this.totallength = this.userListMatTabDataSource.data.length;
    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
    this.userListMatTabDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }

  getAlldata() {
    this.rowID=0;
    this.upholsteryData = [];
    this.availabilityList = ['OPTIONAL', 'DEFAULT', 'STANDARD'];
    this.modelService.getUpholsteryData(this.vehiclesdetails.versionCode, this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(upholsteryData => {
        if (upholsteryData["upholsteryVO"] != null) {
          upholsteryData["upholsteryVO"].forEach(rowData => {
            rowData.id= ++this.rowID;
            if (rowData.activeIndicator == null || rowData.activeIndicator == "" || rowData.activeIndicator == "null") {
              rowData.activeIndicator = "Y";
            }

            this.upholsteryData.push(rowData);
          });
          this.userListMatTabDataSource.data = this.upholsteryData;
          this.showSpinner = false;
          this.showTable = true;
          setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
          setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
        }
        else {
          this.showSpinner = false;
          this.showTable = true;
        }



      });

  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }
  /*  onAvailabilityChange(upholstery){
     if(upholstery.availability=='DEFAULT'){
       this.availabilityList=['OPTIONAL'];
     }
   } */

  edited(upholstery, index2) {
    if (upholstery.editedCheck != null) {
      for (let index = 0; index < this.editedData.length; index++) {
        if (upholstery.versionCode == this.editedData[index].versionCode &&
          upholstery.optionGrpCode == this.editedData[index].optionGrpCode &&
          upholstery.colorCode == this.editedData[index].colorCode) {
          this.editedData[index].edited = false;
          this.editedData[index].editedCheck = null;
          this.editedData.splice(index, 1);
          this.getAlldata();
        }
      }
    }
    else {
     this.getDiclaimeRefCodes();
      upholstery.edited = true;
      upholstery.editedCheck = upholstery.edited + index2;
      this.editedData.push(upholstery);
    }
  }
  getDiclaimeRefCodes(){
    this.modelService.getDisclaimerRefCode(this.vehiclesdetails.modelYearNumber,
        this.vehiclesdetails.division).subscribe(disclaimerRefCodeData =>{
          this.disclaimerRefCodeData=disclaimerRefCodeData;
          this.filteredSearch.next(this.disclaimerRefCodeData.slice());
          this.disclaimerRefcodeSearch.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filteredSearchData();
            });
       });
  }

  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      if (this.editedData.length > 0) {
        this.editedData.forEach(rowData => {
          rowData.edited = false;
          rowData.editedCheck = null;
          this.upholsteryFinalData.push(rowData);
        });
        this.showSpinner = true;
        this.showTable = false;
        this.modelService.saveUpholsteryData(this.upholsteryFinalData).subscribe(res => {
          this.responseStatusAdd(res);
          this.getAlldata();
          this.upholsteryFinalData = [];
          this.editedData = [];
        });
      }
      else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }
  }

  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000" || resarr.responseCode == "0005") {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
      this.messageService.setMessageInfo(msgObj);
    }
    else {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
  }

  onSortOrderChange(number, index) {
    let compare=index-1;
    if (number.value != null && number.value != "") {
      for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
        if (this.userListMatTabDataSource.data[x].sortOrdernumber != null && compare!=x) {
          console.log(compare,x);
          if (this.userListMatTabDataSource.data[x].sortOrdernumber == number.value) {
            this.testForm.form.get('sortOrdernumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
            break;
          }
        }
      }
    }
  }
  private filteredSearchData() {
    if (!this.disclaimerRefCodeData) {
      return;
    }
    // get the search keyword
    let search = this.disclaimerRefcodeSearch.value;
   
    if (!search) {
      this.filteredSearch.next(this.disclaimerRefCodeData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
  this.filteredSearch.next(
      this.disclaimerRefCodeData.filter(bank => 
        bank.toLowerCase().indexOf(search) > -1)
    );
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}