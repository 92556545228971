import { Component, OnInit,ViewChild,Input,OnChanges,AfterViewInit,Output,EventEmitter} from '@angular/core';
import { ModelService } from '../services/model.service';
import { Model } from './model';
import { MatDialog } from '@angular/material/dialog';
import { VehicleDetails } from '../details/details';
import { MessageService} from '../services/message.service'
import { map } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { NgForm } from '@angular/forms';
import { ConfirmationDialogComponent } from  './../conformationdialogue/conformationdialogue.component';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
  providers: [ MessageService]
})
export class ModelComponent implements OnInit {

  @Input("vehiclesdetails")vehiclesdetails:VehicleDetails;
  modelData: Model[]=[];
  models:any[];
  selectedRow: Number;
  editedRows: Boolean[];
  editedData: any=[];
  totallength:number;
  message:String;
  SelectedLanguages:any[];
  modelFinalData:any=[];
  showMessage:String;
  responseMsg:string;
  msrpAmount: Number;
  newMsrpAmount: Number;
  messageType:String;
  showSpinner:boolean = false;
  showTable:boolean = false;
  @Output() refreshVersion = new EventEmitter<boolean>();
  value:boolean=false;

  public columnList = ["action", "modelYearNumber", "modelLineCode", 
  "modelStartingPriceAmount", "modelNameEnglish","modelNameFrench","modelCode","activeIndicator"];
  userListMatTabDataSource = new MatTableDataSource<Model>(this.modelData);

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('testForm',{static:false}) testForm: NgForm;

  constructor(private modelService:ModelService,private messageService:MessageService,public dialog: MatDialog) { }

  ngOnInit() {
    this.editedRows = [];
    this.editedData = [];
    this.showSpinner=true;
    this.showTable=false;
    this.getAlldata();
    this.totallength=this.userListMatTabDataSource.data.length;
    
     
  }

  applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
  }

  getAlldata() {
    this.modelData=[];
    this.modelService.getModels(this.vehiclesdetails.division,this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.modelLineCode).subscribe(modelsdata=>{
        if( modelsdata["modelVO"]!=null){
          modelsdata["modelVO"].forEach(rowData=>{
            if(rowData.activeIndicator==null || rowData.activeIndicator == "" || rowData.activeIndicator=="null"){
              rowData.activeIndicator="Y";
            }
            this.msrpAmount= rowData.modelStartingPriceAmount;
            this.modelData.push(rowData);
          });
        this.userListMatTabDataSource.data = this.modelData;
        this.showSpinner=false;
        this.showTable=true;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      //  setTimeout(() =>  this.userListMatTabDataSource.sort = this.sort);
        }
        else{
          this.showSpinner=false;
          this.showTable=true;
        }
       
    });
  }

  rowClick(rowId) {
    this.selectedRow = rowId;
  }

   edited(model,index) {
   
       if(model.editedCheck!=null){
      for(let index = 0;index<this.editedData.length;index++){
        if(model.modelStartingPriceAmount==this.editedData[index].modelStartingPriceAmount ){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
          this.getAlldata();
        }
      }
     }
     else{
      model.edited = true;
      model.editedCheck= model.edited+index;
      this.editedData.push(model);
      

     }
    
  }
 save() 
 {
    this.newMsrpAmount=this.editedData[0].modelStartingPriceAmount;
    if(this.msrpAmount==this.newMsrpAmount)
    {
      if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info"}, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      } else {
        if(this.editedData.length>0){
          this.editedData.forEach(rowData=>{
            rowData.edited=false;
            rowData.editedCheck=null;
            this.modelFinalData.push(rowData);
          });
          this.showSpinner=true;
          this.showTable=false;
            this.modelService.saveModelData(this.modelFinalData).subscribe(res=>{
              this.responseStatusAdd(res);
              this.getAlldata();
              this.editedData=[];
              this.modelFinalData=[];
            });
        }
        else{
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
      }

    }
    else
    {
     const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: "There is a difference in MSRP amount between NCI PACE and MCM,are you sure want to save the new amount in NCI MCM"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {

    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info"}, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    }
     else {
      if(this.editedData.length>0){
        this.editedData.forEach(rowData=>{
          rowData.edited=false;
          rowData.editedCheck=null;
          this.modelFinalData.push(rowData);
        });
        this.showSpinner=true;
        this.showTable=false;
          this.modelService.saveModelData(this.modelFinalData).subscribe(res=>{
            this.responseStatusAdd(res);
            this.getAlldata();
            this.editedData=[];
            this.modelFinalData=[];
          });
      }
      else{
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
      }
    }
  }
  else
  {

  }
});
    }
  }
  responseStatusAdd(res) {
    let resarr = res;
    if (resarr.responseCode == "0000") {
      this.value=true;
      this.refreshVersion.emit(this.value);
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
        this.messageService.setMessageInfo(msgObj);
    }
    else {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
    }
  }

}



