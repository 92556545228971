import { Component, OnInit,HostListener,ViewChild,Inject,Input } from '@angular/core';
import { ModelService } from '../services/model.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog} from '@angular/material/dialog';
import { MessageService} from '../services/message.service'
import {Complexity} from "./complexity";
import {FormControl, Validators,NgForm} from '@angular/forms';
import { VehicleDetails } from './../details/details';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { templateJitUrl } from '@angular/compiler';
import { ConfirmationDialogComponent } from  './../conformationdialogue/conformationdialogue.component';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-complexity',
  templateUrl: './complexity.component.html',
  styleUrls: ['./complexity.component.scss'],
  providers: [ MessageService]
})
export class ComplexityComponent  {
  @Input("vehiclesdetails")vehiclesdetails:VehicleDetails;

  complexity:Complexity;
  complexityData: Complexity[]=[];
  editedData: any=[];
  interiorColorList:any=[];
  selectedRow: number;
  VersionTrim:String;
  PackageIsPresent:boolean=false;
  totalCount:number;
  public columnList :any=[];
  public  packageList:any=[{columnDef:"action"},{columnDef:"code"},{columnDef:"name"},{columnDef:"type"},{columnDef:"color"},{columnDef:"version"}];
  public packageData:any=[];
  public packageWithExteriorData:any=[];
  public exteriorColors:any=[];
  public interiorColors:any=[];
  public packages:any=[];
  public versionCode:String;
  public inputData:any=[];
  public messageValue:String;
  showSpinner: boolean = false;
  showTable: boolean = false; 

  userListMatTabDataSource = new MatTableDataSource<Complexity>(this.complexityData);
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild('testForm',{static:false}) testForm: NgForm;
  count:number;
  constructor(private modelService:ModelService,
    private messageService:MessageService,public dialog: MatDialog) { 

  }
  num:number=0;
 
  ngOnInit() {
    this.editedData = [];
    this.showSpinner = true;
    this.showTable = false;
    document.getElementById('whenData').style.display = 'none';
    document.getElementById('show').style.display = 'none';
    this.modelService.getComplexityData(this.vehiclesdetails.modelLineCode,this.vehiclesdetails.modelYearNumber,
      this.vehiclesdetails.versionCode).subscribe(complexityData=>{
          this.getAlldata(complexityData);
      });
    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
    this.userListMatTabDataSource.sort = this.sort; 
   
  }
  getAlldata(complexityDataValue){
    this.complexityData=[];
    this.packageData=[];
    this.packageWithExteriorData=[];
    this.exteriorColors=[];
    this.interiorColors=[];
    this.packages=[];
    console.log(complexityDataValue["message"]);
    if(complexityDataValue["message"]==null){
        if(complexityDataValue["versionDetailsList"].length>0 ){
          document.getElementById('whenData').style.display = 'none';
          document.getElementById('show').style.display = 'block';
          for(let index=0;index<complexityDataValue["versionDetailsList"].length;index++){
            if(index==0){
              this.versionCode=complexityDataValue["versionDetailsList"][index].id; 
              this.VersionTrim= complexityDataValue["versionDetailsList"][index].name +" ("+complexityDataValue["versionDetailsList"][index].id+")";
            }
           else{
              this.packages.push(complexityDataValue["versionDetailsList"][index].id);
              const temObj=Object.assign({columnDef:complexityDataValue["versionDetailsList"][index].id, 
              header: complexityDataValue["versionDetailsList"][index].name,});
              this.packageData.push(temObj);
              this.packageList.push(temObj);
             }
          }
          if(this.packages!=null){
            this.PackageIsPresent=true;
          }
          this.columnList=this.packageList.map(c=>c.columnDef);
          this.interiorColorList=complexityDataValue["interiorColorsList"];
          if(complexityDataValue["exteriorColorsList"]!=null && complexityDataValue["exteriorColorsList"].length>0){
            complexityDataValue["exteriorColorsList"].forEach(element => {
              element.rowId=this.num++;
              this.exteriorColors.push(element.id)
              this.complexityData.push(element);
           });
          }
          this.userListMatTabDataSource.data = this.complexityData;
          this.showSpinner = false;
          this.showTable = true;
          if(complexityDataValue["interiorColorsList"]!=null && complexityDataValue["interiorColorsList"].length>0){
            complexityDataValue["interiorColorsList"].forEach(element => {
              this.interiorColors.push(element.id);});
          }
         
           if(this.packages!=null){
           this.interiorColors.forEach(element => {
           this.exteriorColors.forEach(row => {
            this.packages.forEach(data=>{
                const tempObj=Object.assign({exteriorColor:row,interiorColor:element,optiongroupCode:data,status:""});
                this.packageWithExteriorData.push(tempObj);     
              });   
             });
            });
           }
        }
        else{
          this.showSpinner = false;
          this.showTable = true;
        }
      }
        else{
          this.messageValue=complexityDataValue["message"];
        
          this.userListMatTabDataSource.data = [];
          document.getElementById('whenData').style.display = 'block';
          document.getElementById('show').style.display = 'none';
          this.showSpinner = false;
          this.showTable = true;
        }
        
  }
  addRow(complexity) {
           
           let complexitynewRow = new Complexity();
           complexitynewRow.edited=true; 
           complexitynewRow.editedCheck="New";
           complexitynewRow.id=complexity.id;
           complexitynewRow.name=complexity.name;
            var newRowId=complexity.rowId
            complexitynewRow.rowId=++(newRowId);
            var n=this.complexityData.length;
            var newRowId1= complexitynewRow.rowId;
           this.complexityData.splice(newRowId1,0, complexitynewRow);
           this.complexityData.forEach(rowData=>{
            rowData.rowId=this.complexityData.indexOf(rowData);
           })
          this.editedData.push(complexitynewRow);

           console.log(this.complexityData);
           this.userListMatTabDataSource.data = [];
           this.userListMatTabDataSource.data = this.complexityData;
           console.log(this.userListMatTabDataSource.data );

           
  }


  edited(complexity,index2) {
    if(complexity.editedCheck!=null && complexity.editedCheck!="New"){
     
      this.packageWithExteriorData=this.packageWithExteriorData.map(x=>{
        x.status == "A" && x.exteriorColor==complexity.id && x.interiorColor == complexity.color ? x.status="" : x.status;
          return x;}
      );
      let data = this.packageWithExteriorData.filter(x => x.exteriorColor==complexity.id && x.interiorColor == complexity.color);
      console.log(data);
      for(let index = 0;index<this.editedData.length;index++){
        if(this.editedData[index].editedCheck==complexity.editedCheck){
          this.editedData[index].edited=false;
          this.editedData[index].editedCheck=null;
          this.editedData.splice(index,1);
        }
      } 
     }
     else if(complexity.editedCheck!=null && complexity.editedCheck=="New"){
      this.packageWithExteriorData=this.packageWithExteriorData.map(x=>{
        x.status == "A" && x.exteriorColor==complexity.id && x.interiorColor == complexity.color ? x.status="" : x.status;
          return x;}
      );
      complexity.edited=false;
      complexity.editedCheck=null;
      this.complexityData.splice(index2,1);
      this.userListMatTabDataSource.data = [];
      this.userListMatTabDataSource.data = this.complexityData;
      setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
      setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
     }
     else{
        complexity.edited=true;
        complexity.editedCheck=index2;
        this.editedData.push(complexity);
     }
  }


  OnColorchange(complexity){
    this.interiorColorList.forEach(element => {
      if(complexity.color==element.id){
        complexity.type=element.name;
      }
      
    });
  }
  save() {
    if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
      let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
      this.messageService.setMessageInfo(msgObj);
    } else {
      this.inputData=[];
        let versionData = this.editedData.filter(data=>data.edited==true);
        let data = this.packageWithExteriorData.filter(x => x.status != "");
        if( versionData!=null && versionData.length>0){
          data.forEach(element => {
            this.inputData.push(element);
          });
          versionData.forEach(row=>{
            const tempObj=Object.assign({exteriorColor:row.id,
              interiorColor:row.color,
              optiongroupCode:this.versionCode,
              status:row.version});
              this.inputData.push(tempObj);
          });
          let requestValue = {
            "modelCode": this.vehiclesdetails.modelLineCode,
            "modelYear": this.vehiclesdetails.modelYearNumber,
            "versionCode": this.vehiclesdetails.versionCode,
            "exteriorColors": this.exteriorColors,
            "interiorColors": this.interiorColors,
            "packages":this.packages,
            "input": this.inputData
          }
       
          this.modelService.saveComplexityData(requestValue).subscribe(res=>{
            this.responseStatusAdd(res);
            this.modelService.getComplexityData(this.vehiclesdetails.modelLineCode,this.vehiclesdetails.modelYearNumber,
            this.vehiclesdetails.versionCode).subscribe(complexityData=>{
            this.getAlldata(complexityData);
              });
        });
      }
        else{
          this.inputData=[];
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
        }
    }
  }
  applyFilter(filterValue: string) {
    
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    
  }
  rowClick(rowId) {
    this.selectedRow = rowId;
    console.log(rowId);
  }
  responseStatusAdd(res) {
      let resarr = res;
      if (resarr.responseCode == "0000") {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Success" });
          this.messageService.setMessageInfo(msgObj);
      }
      else {
          let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
          this.messageService.setMessageInfo(msgObj);
      }
  }

  reset(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you  want to reset the data?"
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.showSpinner = true;
          this.showTable = false;
          this.modelService.resetComplexityData(this.vehiclesdetails.modelLineCode,this.vehiclesdetails.modelYearNumber,
            this.vehiclesdetails.versionCode).subscribe(complexityData=>{
              if(complexityData["versionDetailsList"].length>0){
                this.showSpinner = false;
                this.showTable = true;
                this.getAlldata(complexityData);
              }
              else{
                this.showSpinner = false;
                this.showTable = true;
              }
       }); 
      }
    });
  }
  
}