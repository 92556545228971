export class Accessories{    
accessoryType:String;
accessorySubType:any[];
modelLineCode:  String;
modelYearNumber:  String;
accessoryCode:  String;
partNumber: String;
accessoryDesc: String;
msrp:  String;
versionKey:any;
packageCode:String;
constraintType:String
accessoryEngName:String;
accessoryFrnName:String;
installationOption:String;
featureDescriptionEng:String;
featureDescriptionFrn:String;
//sortOrdernumber:String;
disclaimerRefCode:String;
selectVersions:any[];
checkedVersionsList:any[];
versionsAvailability:any[];
activeIndicator:  String;
mostPopular: String;
edited: boolean=undefined;
editedCheck:String;
}