import { Component,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


import { MatAccordion } from '@angular/material';
import { AuthenticationService } from './services/authentication.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from './settings/settings.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent {
  title = 'mcm';
  navLinks: any[];
  activeLinkIndex = -1; 
  panelOpenState = false;
  data: any[];
  details: boolean = true;
 
  accordionList:any;
  @ViewChild(SettingsComponent,{static:true}) settingsdata;
  @ViewChild('accordion',{static:true}) Accordion: MatAccordion;
 
  constructor(private router: Router,
    private authenticationService: AuthenticationService,private httpClient: HttpClient) {
      this.navLinks = [
       /*  {
          label: 'Home',
          link: './home',
          index: 0
        }, */
      //   {
      //   label: 'Vehicle Details',
      //   link: './details',
      //   index: 1
      // },
      {
        label: 'Home',
        link: './',
        index: 1
      },
      {
        label: 'Admin',
        link: './settings',
         index: 1
      }

        /* {
          label: 'Model',
          link: './model',
          index: 1
        },
        {
          label: 'Version',
          link: './version',
          index: 2
        },
        {
          label: 'Disclaimer',
          link: './disclaimer',
          index: 3
        },
        {
          label: 'Interior',
          link: './interior-color',
          index: 4
        },
        {
          label: 'Accessories',
          link: './accessories',
          index: 5
        }
 */
      ];
      
     
  }
  ngOnInit(): void {
    
    //  this.httpClient.get<any>('https://as.dev.na.nissan.biz/SecureAuth931').subscribe(data => {
    //         console.log(data);
    //     })
    //   window.open("https://as.dev.na.nissan.biz/SecureAuth931",'_self', 'height=200,width=150'
    // );
    // this.authenticationService.getSecure().subscribe(res=>{
    //   console.log(res);
    // });
    

    this.authenticationService.generateToken();
    this.router.events.subscribe((res) => {
        this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }
  goToSettings() {
    this.router.navigate(['/settings']);  
   }
   goToLogout()
   {
    this.router.navigate(['/Home']);  
   }
 
}