import { Component, OnInit , Inject,ViewChild, Version,} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModelService } from '../../services/model.service';
import { FormControl, Validators,NgForm } from '@angular/forms';
import { MessageService } from '../../services/message.service'
import { EIMDescription } from './eimdescription';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { version } from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from  './../../conformationdialogue/conformationdialogue.component';



@Component({
  selector: 'app-eimdescription',
  templateUrl: './eimdescription.component.html',
  styleUrls: ['./eimdescription.component.scss'],
  providers: [MessageService]
})

export class EIMDescriptionComponent implements OnInit  {

    
    eimDescriptionData: EIMDescription[]=[];
    selectedRow: number;
    editedRows: Boolean[];
    editedData: any=[];
    eimDescriptionFinalData:any=[];
    showSpinner:boolean = false;
    showTable:boolean = false;
    showMessage: String;
    responseMsg: string;
    messageType: String;
  
    @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:false}) sort: MatSort;
    @ViewChild('testForm',{static:false}) testForm: NgForm;
    constructor(public dialogRef: MatDialogRef<EIMDescriptionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EIMDescription,
        private modelService: ModelService,private messageService:MessageService,public dialog: MatDialog) {
    }
    public columnList = ['action',
	'eimOptionData', 
    'eimOptionDesc' ];
    userListMatTabDataSource = new MatTableDataSource<EIMDescription>(this.eimDescriptionData);


    ngOnInit() {
        //console.log("dataaaaaa",this.data["eimjson"].eimDescVO);
      this.editedRows = [];
      this.editedData = [];
      this.showSpinner=true;
      this.showTable=false;
      this.getAlldata();
    }
  
    getAlldata() {
        this.eimDescriptionData=[];
        
           if(this.data["eimjson"].eimDescVO!=null){
           this.eimDescriptionData=this.data["eimjson"].eimDescVO;
           this.userListMatTabDataSource.data=this.eimDescriptionData;
           this.showSpinner=false;
           this.showTable=true; 
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
        }
        else{
            this.showSpinner=false;
           this.showTable=true; 
        } 
        
     
    }
  
    addRow() {

      if(this.userListMatTabDataSource.data !=null && this.userListMatTabDataSource.data.length!=0)
      {
        
        let eimdescription = new EIMDescription();
        eimdescription.edited=true; 
        eimdescription.editedCheck="New";
        this.eimDescriptionData.splice(0, 0, eimdescription);
        this.editedData.push(eimdescription);
        this.userListMatTabDataSource.data = [];
        this.userListMatTabDataSource.data = this.eimDescriptionData;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
      else 
      {
           
            this.eimDescriptionData=[];
            let eimdescription = new EIMDescription();
            eimdescription.edited=true; 
            eimdescription.editedCheck="New";
            this.eimDescriptionData.push(eimdescription);
            this.editedData.push(eimdescription);
            this.userListMatTabDataSource.data = [];
            this.userListMatTabDataSource.data = this.eimDescriptionData;
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
    }
    applyFilter(filterValue: string) {
      this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
    }
  
    rowClick(rowId) {
      this.selectedRow = rowId;
    }
  
    edited(eimdescription,index2) {
            if(eimdescription.editedCheck!=null && eimdescription.editedCheck!="New"){
                for(let index = 0;index<this.editedData.length;index++){
                  if(this.editedData[index].editedCheck==eimdescription.editedCheck){
                    this.editedData[index].edited=false;
                    this.editedData[index].editedCheck=null;
                    this.editedData.splice(index,1);
                  }
                } 
               }
               else if(eimdescription.editedCheck!=null && eimdescription.editedCheck=="New"){
                eimdescription.edited=false;
                eimdescription.editedCheck=null;
                this.eimDescriptionData.splice(index2,1);
                this.userListMatTabDataSource.data = [];
                this.userListMatTabDataSource.data = this.eimDescriptionData;
                setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
                setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
               }
               else{
                eimdescription.edited = true;
                eimdescription.editedCheck=index2;
                this.editedData.push(eimdescription);
               }  
               
    }
    save() {
        if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
    
        } else {
            if(this.editedData.length>0){
                this.editedData.forEach(row=>{
                    if(row.edited==true){
                        this.eimDescriptionFinalData.push(row);
                        row.edited=false;
                        row.editedCheck=null;
                    }
                });
            }
                if(this.eimDescriptionFinalData.length>0){
                    this.showSpinner=true;
                    this.showTable=false;
                    //console.log(this.versionkeyFeatureFinalData);
                   /*  this.modelService.saveVersionkeyFeatureData(this.versionkeyFeatureFinalData).subscribe(res=>{
                        this.responseStatusAdd(res);
                        this.getAlldata();
                        this.editedData=[];
                        this.versionkeyFeatureFinalData=[];
                    }); */
                }
                else{
                    let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
                    this.messageService.setMessageInfo(msgObj); 
                }
              
        }
    
   } 
    cancel(){
        if(this.editedData.length>0){
            this.editedData.forEach(row=>{
                if(row.edited==true){
                    const tempObj={"eimOptionData":row.eimOptionData,"eimOptionDesc":row.eimOptionDesc};
                    row.edited=false;
                    row.editedCheck=null;
                    this.eimDescriptionFinalData.push(tempObj);
                }
            });
        }
       
    }
    
    responseStatusAdd(res) {
        let resarr = res;
        if (resarr.responseCode == "0000") {
        
            let msgObj = Object.assign({ "visible": true }, {"reponseMsg":  resarr.responseDesc }, { "type": "Success" });
            this.messageService.setMessageInfo(msgObj);
        }
        else {
            let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
            this.messageService.setMessageInfo(msgObj);
        }
    }

   /*  deleteVersionkeyFeature(versionkeyFeature,index2){
        if(versionkeyFeature.editedCheck!=null && versionkeyFeature.editedCheck=="New"){
            this.versionkeyFeatureData.splice(index2,1);
            this.userListMatTabDataSource.data = [];
            this.userListMatTabDataSource.data = this.versionkeyFeatureData;
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
           }
           else{
           
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: "Do you confirm the deletion of this data?"
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result) {
                    versionkeyFeature.edited=false;
                    versionkeyFeature.editedCheck=null;
                    this.versionkeyFeatureData.splice(index2,1);
                    this.userListMatTabDataSource.data = [];
                    this.userListMatTabDataSource.data = this.versionkeyFeatureData;
                    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
                    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
                  this.modelService.deleteversionkeyFeature(versionkeyFeature.versionKey,versionkeyFeature.displayOrderNumber).subscribe(res=>{
                    this.responseStatusAdd(res);
                });
                }
              });
           
           }
    } */
    
   

}
