export class Disclaimer{
    modelKey:String;
    languageCode:String;
    disclaimerReferenceCode:String;
    disclaimerShortDescription:String;
    activeIndicator:String;
    disclaimerText:String;    
    localizedDisclaimerKey:any;
    modelYearNumber:String;
    modelLineCode:String;
    disclaimerTextInEnglish:String;
    disclaimerTextInFrench:String;    
    edited:boolean = undefined;
    editedCheck:String;

}
