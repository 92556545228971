
export class Specification {
	id:number;
    type:String;
	subType:String;
	modelLineCode:String;
	modelYear:String;
	versionCode:String;
	versionKey:String;
	optionGrpCode:String;
	eqpmntCode:String;
	specificationNameEnglish:String;
	specificationNameFrench:String;
	msrp:String;
	catCode:String;
	checkedVersionsCodeList:any[];
	versionsAvailability:any[];
	activeIndicator:String;
	equipmentKey:String;
	sortOrdernumber:String;
	disclaimerRefCode:String;
	availability:String;
	valueEnglish:String;
	valueFrench:String;
	eqpmntTypTxt:String;
    availablity:String;
    edited:boolean = undefined;
    editedCheck:String;


}







