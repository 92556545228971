
export class Complexity {
	rowId:number;
	id:String;
	name:String;
	color:String;
	type:String;
	version:String;
    edited:boolean = undefined;
    editedCheck:String;
}




