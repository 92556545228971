
export class Exterior {
	  id:number;
      modelLineCode:String;
	  modelYear:String;
	  versionCode:String;
	  versionKey:any;
	  optionGrpCode:String;
	  colorCode:String;
	  colorDescriptionEnglish:String;
	  colorDescriptionFrench:String;
	  msrp:String;
	  activeIndicator:String;
	  equipmentKey:String;
	  sortOrdernumber:String;
	  disclaimerRefCode:String;
	  availablity:String;
	  edited:boolean = undefined;
	  editedCheck:String;

}




