import { Component, OnInit ,HostListener} from '@angular/core';
import  { ModelService } from '../services/model.service';
import { MatDialog } from '@angular/material';
import { Model } from '../model/model';
import {FormControl, Validators} from '@angular/forms';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
 /*  CAROUSEL_BREAKPOINT = 768;
  carouselDisplayMode = 'multiple';
  modelData:Model;
  slides: any = [[]];
  cards:any=[];
  slideType:String;
 
  constructor(private modelService:ModelService,public dialog: MatDialog)
    {
     
      if ($(".reveal")) {
        $(".reveal").remove();
      }
      this.modelData = new Model();
    }

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  formControl =new FormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  } */
  ngOnInit() {
   /*  this.modelService.getModels().subscribe(data =>
      {
        this.cards=data;
        this.slides = this.chunk(this.cards, 3);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
          this.carouselDisplayMode = 'single';
          this.slideType='slide';
        } else {
          this.carouselDisplayMode = 'multiple';
          if(this.cards.length==3 && this.cards.length<3){
            this.slideType='';
          }
          else{
            this.slideType='slide';
          }
        }
      }); */
   
  }

  /* @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
      this.carouselDisplayMode = 'single';
      this.slideType='slide';
    } else {
      this.carouselDisplayMode = 'multiple';
      if(this.cards.length==3 || this.cards.length < 3){
        this.slideType='';
      }
      else{
        this.slideType='slide';
      }
    }
  }
  modelKey: string;
  index: number;



  updateDetails(data){
    this.onEditClick(data); 
  }
  onEditClick(data: any)
  {
   
    //this.disable=true;
   // this.modelData.languageCode=data.languageCode;
    this.modelData.modelYearNumber=data.modelYearNumber;
    this.modelData.modelLineCode=data.modelLineCode;
    this.modelData.divisionCode=data.divisionCode;
    //this.modelData.modelName=data.modelName;
   // this.modelData.vehicleTypeDescription=data.vehicleTypeDescription;
    this.modelData.modelStartingPriceAmount=data.modelStartingPriceAmount;
    this.modelData.countryCode=data.countryCode;
    this.modelData.modelKey=data.modelKey;
    this.modelData.activeIndicator=data.activeIndicator;
    $(document).foundation();
    $("#editProperty").foundation("open"); */
 
  /*  $(document).foundation();
    $("#editProperty").foundation("open"); 
   */
   /*  this.modelService.getModels().subscribe(data =>
      {
        this.cards=data;
        this.slides = this.chunk(this.cards, 3);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
          this.carouselDisplayMode = 'single';
          this.slideType='slide';
        } else {
          this.carouselDisplayMode = 'multiple';
          if(this.cards.length==3 && this.cards.length<3){
            this.slideType='';
          }
          else{
            this.slideType='slide';
          }
        }
      }); */
 /*  }
  closeModalPopup() {
    $(".close-button").trigger("click");
  }

  editData()
  { */
  // this.modelService.updateModel(this.modelData).subscribe(()=>{
  //   this.modelService.getModels().subscribe(data =>
  //     {
  //       this.cards=data;
  //       this.slides = this.chunk(this.cards, 3);
  //       if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
  //         this.carouselDisplayMode = 'single';
  //         this.slideType='slide';
  //       } else {
  //         this.carouselDisplayMode = 'multiple';
  //         if(this.cards.length==3 && this.cards.length<3){
  //           this.slideType='';
  //         }
  //         else{
  //           this.slideType='slide';
  //         }
  //       }
  //     });
  //   });
  //   this.closeModalPopup();
  //}

}
