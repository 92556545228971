import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ModelComponent } from './model/model.component';
import { VersionComponent } from './version/version.component';

import { DisclaimerComponent } from '../app/dislcaimer/disclaimer.component';
import{InteriorColourComponent} from './interior-colour/interior-colour.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { VehicleDetailsComponent } from './details/details.component';
import { SettingsComponent } from './settings/settings.component';

//import {CreateInteriorColourComponent} from './interior-colour/create-interior-colour/create-interior-colour.component';
// import { ModelsComponent } from './models/models.component';

const routes: Routes = [
  {path:'',redirectTo:'/', pathMatch:'full'},
  {path:'settings', component:SettingsComponent},
 //{path:'',redirectTo:'details1', pathMatch:'https://as.dev.na.nissan.biz/SecureAuth931'},
   
  // {
  //   path: 'details1',
  //   redirectTo: 'https://as.dev.na.nissan.biz/SecureAuth931'
  // },
 /*  {path:'details/model', component:ModelComponent},
  {path:'details/createmodel', component:CreatemodelComponent},
  {path:'details/updatemodel', component:UpdatemodelComponent},
  {path:'details/deletemodel', component:DeletemodelComponent},
  {path:'details/version', component:VersionComponent},
  {path:'details/createversion', component:CreateversionComponent},
  {path:'details/disclaimer',component:DisclaimerComponent},
  {path:'details/interior-color',component:InteriorColourComponent},
  {path:'details/accessories',component:AccessoriesComponent}, */
  //{path:'details',component:VehicleDetailsComponent},
  {path:'**',component:VehicleDetailsComponent}
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
