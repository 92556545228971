import { Component, OnInit , Inject,ViewChild, Version,} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ModelService } from '../../services/model.service';
import { FormControl, Validators,NgForm } from '@angular/forms';
import { MessageService } from '../../services/message.service'
import { VersionkeyFeature } from './versionkeyFeature';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { version } from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from  './../../conformationdialogue/conformationdialogue.component';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-versionkeyFeature',
  templateUrl: './versionkeyFeature.component.html',
  styleUrls: ['./versionkeyFeature.component.scss'],
  providers: [MessageService]
})

export class VersionkeyFeatureComponent implements OnInit  {

    
    versionkeyFeatureData: VersionkeyFeature[]=[];
    selectedRow: number;
    editedRows: Boolean[];
    editedData: any=[];
    disclaimerRefCodeData:any=[];
   
    versionkeyFeatureFinalData:any=[];
    showSpinner:boolean = false;
    showTable:boolean = false;
    showMessage: String;
    responseMsg: string;
    messageType: String;
    public disclaimerRefcodeSearch: FormControl = new FormControl();
    public filteredSearch: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _onDestroy = new Subject<void>();
    rowID:number=0;


    @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
    @ViewChild(MatSort,{static:false}) sort: MatSort;
    @ViewChild('testForm',{static:false}) testForm: NgForm;
    constructor(public dialogRef: MatDialogRef<VersionkeyFeatureComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modelService: ModelService,private messageService:MessageService,public dialog: MatDialog) {
    }
    public columnList = ['action',
    'displayOrderNumber',
	'disclaimerRefCode',
	'versionFeatureDescInEnglish', 
    'versionFeatureDescInFrench' ];
    userListMatTabDataSource = new MatTableDataSource<VersionkeyFeature>(this.versionkeyFeatureData);


    ngOnInit() {
   
      this.editedRows = [];
      this.editedData = [];
      this.showSpinner=true;
      this.showTable=false;
      this.getAlldata();
    }
  
    getAlldata() {
        this.rowID=0;
        this.versionkeyFeatureData=[];
        this.modelService.getVersionkeyFeatureData(this.data["version"].modelKey,this.data["version"].versionKey).subscribe(versionkeyFeaturedata=>{
           if(versionkeyFeaturedata["versionMarketingFeatureVO"]!=null){
           versionkeyFeaturedata["versionMarketingFeatureVO"].forEach(rowData => {
            rowData.id= ++this.rowID;
            this.versionkeyFeatureData.push(rowData);
          });
           this.userListMatTabDataSource.data=this.versionkeyFeatureData;
           this.showSpinner=false;
           this.showTable=true; 
          
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
          
        }
        else{
            this.showSpinner=false;
           this.showTable=true; 
        } 
        });
     
    }
  getDiclaimeRefCodes(){
    this.modelService.getDisclaimerRefCode(this.data["version"].modelYear,
        this.data.division).subscribe(disclaimerRefCodeData =>{
          this.disclaimerRefCodeData=disclaimerRefCodeData;
          this.filteredSearch.next(this.disclaimerRefCodeData.slice());
          this.disclaimerRefcodeSearch.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filteredSearchData();
            });
       });
  }
    addRow() {

      if(this.userListMatTabDataSource.data !=null && this.userListMatTabDataSource.data.length!=0)
      {

        this.getDiclaimeRefCodes();
        let versionkeyFeature = new VersionkeyFeature();
        versionkeyFeature.edited=true;
        versionkeyFeature.id=++this.rowID;
        versionkeyFeature.modelKey=this.data["version"].modelKey;
        versionkeyFeature.versionKey=this.data["version"].versionKey;
        versionkeyFeature.editedCheck="New";
        this.versionkeyFeatureData.splice(0, 0, versionkeyFeature);
        this.editedData.push(versionkeyFeature);
        this.userListMatTabDataSource.data = [];
        this.userListMatTabDataSource.data = this.versionkeyFeatureData;
        setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
        setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
      else 
      {
            this.getDiclaimeRefCodes();
            this.versionkeyFeatureData=[];
            let versionkeyFeature = new VersionkeyFeature();
            versionkeyFeature.edited=true;
            versionkeyFeature.id=1;
            this.rowID=1;
            versionkeyFeature.disclaimerRefCode="";
            versionkeyFeature.displayOrderNumber="";
            versionkeyFeature.versionFeatureDescInEnglish="";
            versionkeyFeature.versionFeatureDescInFrench="";
            versionkeyFeature.displayType="";
            versionkeyFeature.modelKey=this.data["version"].modelKey;
            versionkeyFeature.versionKey=this.data["version"].versionKey;
            versionkeyFeature.editedCheck="New";
            this.versionkeyFeatureData.push(versionkeyFeature);
            this.editedData.push(versionkeyFeature);
            this.userListMatTabDataSource.data = [];
            this.userListMatTabDataSource.data = this.versionkeyFeatureData;
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort);
      }
    }
    applyFilter(filterValue: string) {
    this.userListMatTabDataSource.filter = filterValue.trim().toLowerCase();
     }
  
    rowClick(rowId) {
      this.selectedRow = rowId;
    }
  
    edited(versionkeyFeature,index2) {
 
            if(versionkeyFeature.editedCheck!=null && versionkeyFeature.editedCheck!="New"){
                for(let index = 0;index<this.editedData.length;index++){
                  if(this.editedData[index].editedCheck==versionkeyFeature.editedCheck){
                    this.editedData[index].edited=false;
                    this.editedData[index].editedCheck=null;
                    this.editedData.splice(index,1);
                  }
                } 
               }
               else{
                this.getDiclaimeRefCodes();
                versionkeyFeature.edited = true;
                versionkeyFeature.editedCheck=index2;
                this.editedData.push(versionkeyFeature);
               }  
               
    }
    save() {
        if ((this.testForm.touched || this.editedData.length > 0) && !this.testForm.valid) {
        let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please check and  provide the required info" }, { "type": "Error" });
        this.messageService.setMessageInfo(msgObj);
    
        } else {
            if(this.editedData.length>0){
                this.editedData.forEach(row=>{
                    if(row.edited==true){
                        this.versionkeyFeatureFinalData.push(row);
                        row.edited=false;
                        row.editedCheck=null;
                    }
                });
            }
                if(this.versionkeyFeatureFinalData.length>0){
                    this.showSpinner=true;
                    this.showTable=false;
                    //console.log(this.versionkeyFeatureFinalData);
                    this.modelService.saveVersionkeyFeatureData(this.versionkeyFeatureFinalData).subscribe(res=>{
                        this.responseStatusAdd(res);
                        this.getAlldata();
                        this.editedData=[];
                        this.versionkeyFeatureFinalData=[];
                    });
                }
                else{
                    let msgObj = Object.assign({ "visible": true }, { "reponseMsg": "Please Edit the data and Click on save" }, { "type": "Error" });
                    this.messageService.setMessageInfo(msgObj); 
                }
              
        }
    
   } 
    cancel(){
        this.dialogRef.close();
    }
    
    responseStatusAdd(res) {
        let resarr = res;
        if (resarr.responseCode == "0000") {
        
            let msgObj = Object.assign({ "visible": true }, {"reponseMsg":  resarr.responseDesc }, { "type": "Success" });
            this.messageService.setMessageInfo(msgObj);
        }
        else {
            let msgObj = Object.assign({ "visible": true }, { "reponseMsg": resarr.responseDesc }, { "type": "Error" });
            this.messageService.setMessageInfo(msgObj);
        }
    }

    deleteVersionkeyFeature(versionkeyFeature,index2){
        if(versionkeyFeature.editedCheck!=null && versionkeyFeature.editedCheck=="New"){
            for(let x=0;x<this.versionkeyFeatureData.length;x++){
                if(index2==this.versionkeyFeatureData[x].id){
                    this.versionkeyFeatureData.splice(x,1);
                }
            }
            for(let x=0;x<this.editedData.length;x++){
                if(index2==this.editedData[x].id){
                    this.editedData.splice(x,1);
                }
            }
            this.userListMatTabDataSource.data = [];
            this.userListMatTabDataSource.data = this.versionkeyFeatureData;
            setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
            setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
           }
           else{
           
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: "Do you confirm the deletion of this data?"
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result) {
                    versionkeyFeature.edited=false;
                    versionkeyFeature.editedCheck=null;
                    for(let x=0;x<this.versionkeyFeatureData.length;x++){
                        if(index2==this.versionkeyFeatureData[x].id){
                            this.versionkeyFeatureData.splice(x,1);
                        }
                    }
                    this.userListMatTabDataSource.data = [];
                    this.userListMatTabDataSource.data = this.versionkeyFeatureData;
                    setTimeout(() =>  this.userListMatTabDataSource.paginator = this.paginator);
                    setTimeout(()=>this.userListMatTabDataSource.sort = this.sort); 
                  this.modelService.deleteversionkeyFeature(versionkeyFeature.versionKey,versionkeyFeature.displayOrderNumber).subscribe(res=>{
                    this.responseStatusAdd(res);
                });
                }
              });
           
           }
    }
    onSortOrderChange(number, index) {
        if (number.value != null && number.value != "") {
          for (let x = 0; x < this.userListMatTabDataSource.data.length; x++) {
                if (this.userListMatTabDataSource.data[x].displayOrderNumber != null &&   this.userListMatTabDataSource.data[x].id!=index &&
                  this.userListMatTabDataSource.data[x].displayOrderNumber == number.value ) {
                    this.testForm.form.get('displayOrderNumber' + '-' + index).setErrors({ 'numberExists': 'Number already Exists' });
                    break;
                }
          }
        }
      }d
    private filteredSearchData() {
        if (!this.disclaimerRefCodeData) {
          return;
        }
        // get the search keyword
        let search = this.disclaimerRefcodeSearch.value;
       
        if (!search) {
          this.filteredSearch.next(this.disclaimerRefCodeData.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the banks
      this.filteredSearch.next(
          this.disclaimerRefCodeData.filter(bank => 
            bank.toLowerCase().indexOf(search) > -1)
        );
      }
      ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
      }

}
