export class Rules{   
    id:number; 
    partNumber:String;
    dependency:String;
    partNumber1:String;
    
    edited: boolean=undefined;
    editedValue:boolean = undefined;
    editedCheck:String;
    }
